import ReactDOM from 'react-dom/client'
// import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'

import App from './App'

import './styles/style.scss'

const container = document.querySelector('.main-wrapper') as HTMLElement
const root = ReactDOM.createRoot(container)

root.render(
	<Router>
		{/* <Provider store={store}> */}
		<App />
		{/* </Provider> */}
	</Router>,
)
