import { ILaws } from '../../types/ILaws'

export const laws: ILaws[] = [
	{
		id: 0,
		title: 'Положение Банка России от 20 апреля 2021 г. № 757-П + ГОСТ Р 57580.1-2017',
		image: (
			<svg
				width='102'
				height='108'
				viewBox='0 0 102 108'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				{' '}
				<path
					d='M59.6505 61.0901C73.1078 57.4842 80.3218 45.6001 76.9395 32.9774C73.5573 20.3547 61.2839 13.3568 47.8266 16.9627L20.0775 24.3981L29.6652 60.1798L21.4239 62.3881L23.6601 70.7337L31.9014 68.5254L33.9978 76.3494L25.7565 78.5577L27.6294 85.5471L35.8706 83.3389L39.6442 97.4221L49.033 94.9063L45.2594 80.8231L69.879 74.2264L68.0061 67.2369L43.3866 73.8337L41.2902 66.0097L59.6505 61.0901ZM50.1467 25.6213C58.2836 23.441 65.4543 27.6691 67.5507 35.4931C69.6472 43.3171 65.5513 50.5642 57.4143 52.7445L39.054 57.6641L31.7864 30.5409L50.1467 25.6213Z'
					fill='#EAEAEA'
				/>{' '}
			</svg>
		),
	},
	{
		id: 1,
		title: 'Федеральный закон «О персональных данных» от 27.07.2006 № 152-ФЗ',
		image: (
			<svg
				width='104'
				height='108'
				viewBox='0 0 104 108'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M25.4207 48.6278L26.1234 64.9106L35.7436 63.248L33.3333 47.4227L48.1987 51.6751L50.1799 41.9936L34.9183 39.6775L43.8133 26.7708L35.153 21.7708L28.4231 35.9275L18.7865 23.8686L11.3926 30.4251L22.508 41.1727L7.59776 46.998L10.9679 56.1606L25.4207 48.6278ZM70.8785 74.8729L71.5813 91.1558L81.2015 89.4931L78.7912 73.6678L93.6566 77.9202L95.6378 68.2387L80.3761 65.9226L89.2712 53.0159L80.6109 48.0159L73.881 62.1726L64.2443 50.1137L56.8505 56.6702L67.9659 67.4178L53.0556 73.2431L56.4258 82.4058L70.8785 74.8729Z'
					fill='#EAEAEA'
				/>
			</svg>
		),
	},
	{
		id: 2,
		title: 'Приказ ФСТЭК России от 25 декабря 2017 г. № 239 КИИ',
		image: (
			<svg
				width='102'
				height='112'
				viewBox='0 0 102 112'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M58.5909 11.7167C60.3289 10.7177 62.5525 11.3149 63.5562 13.0501C69.9334 24.0745 78.436 30.9334 89.1854 33.8136C91.1238 34.333 92.276 36.3204 91.7584 38.2523L86.1322 59.2494C80.0991 81.7653 64.4746 93.9845 40.0046 95.4271C39.2197 95.474 38.4407 95.2652 37.7845 94.8322C17.3138 81.3478 9.8925 62.9535 15.9256 40.4376L21.5518 19.4405C22.0694 17.5086 24.0607 16.3635 25.9994 16.883C36.7356 19.7598 47.5339 18.0718 58.5909 11.7167ZM59.1153 19.6765C48.7875 24.9005 38.2675 26.6848 27.6704 24.688L22.9463 42.3188C20.2588 52.3487 20.6857 60.9408 23.66 68.3349C26.5384 75.4903 32.0582 82.1567 40.7744 88.1139C51.3014 87.3129 59.4148 84.2995 65.4851 79.5419C71.7582 74.6257 76.424 67.3982 79.1116 57.3682L83.8356 39.7377C73.652 36.1672 65.4348 29.3639 59.1153 19.6765Z'
					fill='#EAEAEA'
					stroke='#EAEAEA'
				/>
			</svg>
		),
	},
	{
		id: 3,
		title: (
			<>
				Зарегистрированы в реестре отечественного{' '}
				<a href='https://reestr.digital.gov.ru/reestr/380280/'>ПО</a>
			</>
		),
		image: (
			<svg
				width='102'
				height='112'
				viewBox='0 0 102 112'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M17.4087 50.8113L17.8916 50.6819L17.7622 50.1989L15.5799 42.0543C14.4254 37.7459 16.9711 33.2401 21.3455 32.0679L29.4902 29.8856L29.9731 29.7562L29.8437 29.2732L28.2931 23.4862L28.1637 23.0033L27.6807 23.1327L19.5361 25.315C11.4819 27.4731 6.66886 35.8096 8.82697 43.8637L11.0093 52.0084L11.1387 52.4913L11.6217 52.3619L17.4087 50.8113Z'
					fill='#EAEAEA'
					stroke='#EAEAEA'
				/>
				<path
					d='M24.9301 76.9479L24.8007 76.4649L24.3177 76.5943L18.5307 78.145L18.0478 78.2744L18.1772 78.7573L20.3595 86.902C22.5176 94.9561 30.8541 99.7692 38.9083 97.6111L47.0529 95.4287L47.5359 95.2993L47.4065 94.8164L45.8558 89.0294L45.7264 88.5464L45.2435 88.6758L37.0988 90.8582C32.7931 92.0119 28.2662 89.3982 27.1125 85.0925L24.9301 76.9479Z'
					fill='#EAEAEA'
					stroke='#EAEAEA'
				/>
				<path
					d='M77.6653 34.1478L77.7947 34.6307L78.2776 34.5013L84.0646 32.9507L84.5476 32.8213L84.4182 32.3383L82.2358 24.1937C80.0777 16.1395 71.7413 11.3265 63.6871 13.4846L55.5425 15.6669L55.0595 15.7963L55.1889 16.2793L56.7395 22.0663L56.8689 22.5493L57.3519 22.4198L65.4965 20.2375C69.8022 19.0838 74.3292 21.6974 75.4829 26.0031L77.6653 34.1478Z'
					fill='#EAEAEA'
					stroke='#EAEAEA'
				/>
				<path
					d='M85.1867 60.2839L84.7037 60.4133L84.8332 60.8962L87.0155 69.0409C88.1699 73.3493 85.6243 77.8551 81.2499 79.0273L73.1052 81.2096L72.6223 81.339L72.7517 81.822L74.3023 87.609L74.4317 88.0919L74.9147 87.9625L83.0593 85.7802C91.1135 83.6221 95.9265 75.2856 93.7684 67.2315L91.5861 59.0868L91.4566 58.6038L90.9737 58.7333L85.1867 60.2839Z'
					fill='#EAEAEA'
					stroke='#EAEAEA'
				/>
				<path
					d='M50.2951 59.228L44.5101 66.2775L40.1092 62.0898L47.4976 56.5985L38.3368 55.4752L40.0543 49.6481L48.6055 52.9225L45.1646 44.7672L51.2179 43.1452L52.3156 51.9283L58.084 44.8171L62.485 49.0047L55.1131 54.5579L64.2574 55.6194L62.5399 61.4464L54.0052 58.2339L57.4296 66.3274L51.3763 67.9494L50.2951 59.228Z'
					fill='#EAEAEA'
				/>
			</svg>
		),
	},
]
