import { FC } from 'react'
import classNamesBind from 'classnames/bind'

import { LogoProps } from './Logo.d'

import styles from './Logo.module.scss'
import { Link, useLocation } from 'react-router-dom'
import logo from './../../../assets/images/Logo.png'

const cx = classNamesBind.bind(styles)

const Logo: FC<LogoProps.Props> = (props) => {
	const locate = useLocation()
	const { className } = props

	return (
		<>
			{locate.pathname === '/' ? (
				<div
					className={cx('up')}
					onClick={() => {
						window.scrollTo({
							top: 0,
							behavior: 'smooth',
						})
					}}
				>
					<img
						alt='Логотип'
						src={logo}
						className={cx('logo', className)}
					/>
				</div>
			) : (
				<Link to={'/'}>
					<img
						alt='Логотип'
						src={logo}
						className={cx('logo', className)}
					/>
				</Link>
			)}
		</>
	)
}

export default Logo
