import item1 from './item1.png'
import item2 from './item2.png'
import item3 from './item3.png'
import item4 from './item4.png'
import item5 from './item5.png'
import item6 from './item6.png'
import item7 from './item7.png'
import item8 from './item8.png'
import item9 from './item9.png'
import item10 from './item10.png'
import item11 from './item11.png'

export type IName =
	| 'item1'
	| 'item2'
	| 'item3'
	| 'item4'
	| 'item5'
	| 'item6'
	| 'item7'
	| 'item8'
	| 'item9'
	| 'item10'
	| 'item11'

export const itemsPngMobile = [
	{
		path: item1,
		width: 89,
		height: 40,
	},
	{
		path: item2,
		width: 138,
		height: 40,
	},
	{
		path: item3,
		width: 134,
		height: 40,
	},
	{
		path: item4,
		width: 119,
		height: 40,
	},
	{
		path: item5,
		width: 88,
		height: 40,
	},
	{
		path: item6,
		width: 122,
		height: 40,
	},
	{
		path: item7,
		width: 133,
		height: 40,
	},
	{
		path: item8,
		width: 123,
		height: 40,
	},
	{
		path: item9,
		width: 99,
		height: 40,
	},
	{
		path: item10,
		width: 105,
		height: 40,
	},
	{
		path: item11,
		width: 155,
		height: 40,
	},
]
