import { FC } from 'react'
import classNamesBind from 'classnames/bind'

import { NewsItemProps } from './NewsItem.d'

import styles from './NewsItem.module.scss'
import { Link } from 'react-router-dom'

const cx = classNamesBind.bind(styles)

const NewsItem: FC<NewsItemProps.Props> = (props) => {
	const { info } = props

	return (
		<Link
			to={`/news/${info.id}`}
			className={cx('wrapper')}
		>
			<div>
				<img
					className={cx('image')}
					src={info.image}
					alt={`Новость ${info.id}`}
				/>
				<div className={cx('status')}>{info.status}</div>
				<div className={cx('title')}>{info.title}</div>
			</div>
			<div className={cx('more')}>Читать полностью</div>
		</Link>
	)
}

export default NewsItem
