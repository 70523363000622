/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { FC, useEffect, useRef, useState } from 'react'
import classNamesBind from 'classnames/bind'
import { Engine, Render, World, Bodies, MouseConstraint, Mouse, Runner } from 'matter-js'

import { FirstScreenProps } from './FirstScreen.d'

import styles from './FirstScreen.module.scss'
import { Container } from '../Container'
import { itemsPng } from './itemsPng'
import { itemsPngMobile } from './itemsPng/mobile'

const cx = classNamesBind.bind(styles)

const getRandomBetween = (min: number, max: number) => Math.random() * (max - min) + min

const Title: FC = () => {
	const title = useRef<HTMLDivElement>(null)

	const titles = ['Обучи сотрудников', 'Защити компанию', 'Стань лучше']

	const [activeTitle, setActiveTitle] = useState(0)

	const changeTitle = async () => {
		setTimeout(() => {
			if (title.current) {
				const nextTitleIndex = activeTitle === titles.length - 1 ? 0 : activeTitle + 1
				const nextTitle = titles[nextTitleIndex]

				const oldSpan = title.current.querySelector('span')
				const newSpan = document.createElement('span')
				newSpan.innerHTML = nextTitle

				title.current.appendChild(newSpan)

				oldSpan?.setAttribute('style', `margin-top: ${-1 * oldSpan?.clientHeight}px;`)

				const paddings = window.getComputedStyle(title.current).getPropertyValue('padding')

				const newSpanWidth = newSpan.clientWidth
				title.current.setAttribute('style', `max-width: ${newSpanWidth}px; padding: ${paddings}`)

				setTimeout(() => {
					oldSpan?.remove()
				}, 1000)

				setActiveTitle(nextTitleIndex)
			}
		}, 2000)
	}

	useEffect(() => {
		changeTitle()
	}, [activeTitle])

	return (
		<div className={cx('title')}>
			<div ref={title}>
				<span>{titles[activeTitle]}</span>
			</div>
			вместе с secure-t
		</div>
	)
}

const FirstScreen: FC<FirstScreenProps.Props> = () => {
	const scene = useRef<HTMLDivElement | null>(null)
	const engine = useRef<any>(Engine.create())
	const runner = useRef<any>(Runner.create())
	const mouse = useRef<any>(null)

	useEffect(() => {
		if (!scene.current) return

		const cw = scene.current.clientWidth
		const ch = scene.current.clientHeight

		const world = engine.current.world

		const render = Render.create({
			element: scene.current,
			engine: engine.current,
			options: {
				width: cw,
				height: ch,
				wireframes: false,
				background: 'transparent',
			},
		})

		engine.current.gravity.scale = 0.0004

		Render.run(render)
		Runner.run(runner.current, engine.current)

		const ground = Bodies.rectangle(cw / 2 + 160, ch + 80, cw + 320, 160, {
			render: { fillStyle: 'transparent' },
			isStatic: true,
		})
		const wallLeft = Bodies.rectangle(-80, ch / 2, 160, ch * 2, {
			render: { fillStyle: 'transparent' },
			isStatic: true,
		})
		const wallRight = Bodies.rectangle(cw + 80, ch / 2, 160, ch * 2, {
			render: { fillStyle: 'transparent' },
			isStatic: true,
		})
		const roof = Bodies.rectangle(cw / 2 + 160, -1 * ch * 2, cw + 320, 160, {
			render: { fillStyle: 'transparent' },
			isStatic: true,
		})

		World.add(world, [ground, wallLeft, wallRight, roof])

		const radius = 20

		if (document.body.clientWidth < 1000) {
			itemsPngMobile.forEach((item) => {
				const body = Bodies.rectangle(
					getRandomBetween(0, cw),
					getRandomBetween(-500, -100),
					item.width,
					item.height,
					{
						chamfer: { radius: radius },
						render: { sprite: { texture: item.path, xScale: 0.5, yScale: 0.5 } },
					},
				)

				World.add(world, body)
			})
		} else {
			itemsPng.forEach((item) => {
				const body = Bodies.rectangle(
					getRandomBetween(0, cw),
					getRandomBetween(-500, -100),
					item.width,
					item.height,
					{
						chamfer: { radius: radius },
						render: { sprite: { texture: item.path, xScale: 1, yScale: 1 } },
					},
				)

				World.add(world, body)
			})
		}

		engine.current.world.gravity = 0.5

		mouse.current = Mouse.create(render.canvas)
		const mouseConstraint = MouseConstraint.create(engine.current, {
			mouse: mouse.current,
			constraint: {
				stiffness: 0.2,
				render: {
					visible: false,
				},
			},
		})

		World.add(world, mouseConstraint)

		render.mouse = mouse.current

		// // Allow page scrolling in matter.js window
		mouse.current.element.removeEventListener('mousewheel', mouse.current.mousewheel)
		mouse.current.element.removeEventListener('DOMMouseScroll', mouse.current.mousewheel)

		// const handleResize = (e: Event) => {
		// 	if (!scene.current) return

		// 	const maxWidth = 1290

		// 	if (scene.current.clientWidth > document.body.clientWidth) {
		// 		render.canvas.width = document.documentElement.clientWidth
		// 		console.log(render.canvas.width)
		// 		// render.current.canvas.width = document.documentElement.clientWidth
		// 	}

		// 	// console.log(document.body.clientWidth)
		// 	// console.log(scene.current.clientWidth)
		// }

		// window.addEventListener('resize', handleResize)

		// run the engine
		Engine.run(engine.current)

		// run the renderer
		Render.run(render)

		// return () => {
		// 	window.removeEventListener('resize', handleResize)
		// }
	}, [scene.current])

	return (
		<Container className={cx('wrapper')}>
			<Title />
			<div
				ref={scene}
				className={cx('scene')}
			></div>
		</Container>
	)
}

export default FirstScreen
