import { FC } from 'react'
import classNamesBind from 'classnames/bind'

import InputMask from 'react-input-mask'

import { AdviceProps } from './Advice.d'

import styles from './Advice.module.scss'
import { Container } from '../Container'
import { useContact } from '../../../hooks'
import { Link } from 'react-router-dom'

const cx = classNamesBind.bind(styles)

const Advice: FC<AdviceProps.Props> = () => {
	const { email, name, phone, setEmail, setName, setPhone, submit, loading, status, errors } =
		useContact()

	const handleSubmit = (event: any) => {
		event.preventDefault()

		submit('null')
	}

	return (
		<Container className={cx('wrapper')}>
			<div className={cx('title')}>Получить консультацию</div>
			<Container className={cx('subtitle')}>
				Расскажем, как защитить вашу компанию от киберугроз и подберём удобное решение
			</Container>
			<div className={cx('inner')}>
				<div>
					<div className={cx('inner-title')}>Оставьте заявку</div>
					<form className={cx('form')}>
						<input
							type='text'
							placeholder='Имя'
							value={name}
							onChange={(e) => setName(e.target.value)}
							className={cx(`${errors.includes('name') ? 'error' : ''}`)}
						/>
						<input
							type='text'
							placeholder='Почта'
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							className={cx(`${errors.includes('email') ? 'error' : ''}`)}
						/>
						<InputMask
							type='text'
							placeholder='Телефон'
							mask='+7 (999) 999-99-99'
							value={phone}
							onChange={(e) => setPhone(e.target.value)}
							className={cx(`${errors.includes('phone') ? 'error' : ''}`)}
						/>
						<div className={cx('agree')}>
							Нажимая отправить, вы даете{' '}
							<Link
								to='/agreement'
								target='_blank'
							>
								согласие
							</Link>{' '}
							на обработку персональных данных и соглашаетесь с{' '}
							<Link
								target='_blank'
								to='/privacy'
							>
								политикой в отношении обработки персональных данных.
							</Link>
						</div>
						<button onClick={handleSubmit}>
							{loading
								? 'ЗАГРУЗКА...'
								: status === 'ok'
									? 'Успешно'
									: status === 'error'
										? 'Ошибка'
										: 'Отправить'}
						</button>
					</form>
				</div>
				<div>
					<div className={cx('inner-title')}>или свяжитесь с нами сами</div>
					<a
						href='tel:+74951055485'
						className={cx('phone')}
					>
						+ 7 495 105 54 85
					</a>
					<a
						href='mailto:info@secure-t.ru'
						className={cx('mail')}
					>
						info@secure-t.ru
					</a>
				</div>
			</div>
		</Container>
	)
}

export default Advice
