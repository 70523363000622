import { FC } from 'react'
import classNamesBind from 'classnames/bind'

import { VacancyPageProps } from './VacancyPage.d'

import styles from './VacancyPage.module.scss'
import { Container, Vacancies } from '../../components/common'

const cx = classNamesBind.bind(styles)

const VacancyPage: FC<VacancyPageProps.Props> = () => {
	return (
		<div className={cx('wrapper')}>
			<Container className={cx('container')}>
				<Vacancies />
			</Container>
		</div>
	)
}

export default VacancyPage
