const TG_BOT_TOKEN = process.env.REACT_APP_TG_BOT_TOKEN
const TG_BOT_CHAT_ID = process.env.REACT_APP_TG_CHAT_ID


type BodyType  = {
	name: string
	subject: string
	email: string
	phone: string
}


export class TgAPI {
	static createMessage(data: BodyType): string {
		const message = `${data.name} оставил запрос на\n Тема ${data.subject}.\n Данные для связи:\n email ${data.email}\n телефон ${data.phone}`
		return message
	}
	static request(data: BodyType) {
		return new Promise((res, rej) => {
			const headers = new Headers()
			headers.append('Content-Type', 'application/json')
			const body = JSON.stringify({
				chat_id: TG_BOT_CHAT_ID,
				text: this.createMessage(data)
			});

			fetch(`https://api.telegram.org/bot${TG_BOT_TOKEN}/sendMessage`, {
				method: 'POST',
				headers,
				body
			})
				.then((res) => {
					if (!res.ok) rej(res.statusText)
					else return res.text()
					return
				})
				.then((res) => (res ? JSON.parse(res || '') : {}))
				.then((r) => res(r))
				.catch((err) => rej(err))
		})
	}

	static Feedback(name: string, phone: string, email: string) {
		return this.request({
			name,
			phone,
			email,
			subject: "Получение демо"
		})
	}
}