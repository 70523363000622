import { FC, useContext } from 'react'
import classNamesBind from 'classnames/bind'

import { LicenseProps } from './License.d'

import styles from './License.module.scss'
import { Container } from '../Container'
import { licenses } from '../../../assets/mocs/license'
import { ID, UUID } from '../../../types/ID'
import { ModalContext } from '../../../contexts'

const cx = classNamesBind.bind(styles)

const License: FC<LicenseProps.Props> = () => {
	const { openModal } = useContext(ModalContext)

	const handleClick = (title: string) => {
		openModal(title)
	}

	return (
		<Container className={cx('wrapper')}>
			<div
				className={cx('title')}
				id='licenses'
			>
				Типы лицензий
			</div>
			<Container className={cx('subtitle')}>
				Указана стартовая стоимость лицензий, чем больше у вас сотрудников &mdash; тем ниже цена за
				человека
			</Container>
			<div className={cx('scroll-wrapper')}>
				<div className={cx('inner')}>
					{licenses.length &&
						licenses.map((item) => {
							return (
								<div
									key={`license-${item.id}`}
									className={cx('item', `${item.isDark ? 'dark' : ''}`)}
								>
									<div className={cx('item-title')}>{item.title}</div>
									<div className={cx('item-text')}>{item.subtitle}</div>
									<div className={cx('item-price')}>
										{item.price} <span>₽</span>
									</div>
									<div className={cx('item-price-for')}>{item.priceFor}</div>
									<button onClick={() => handleClick(item.title)}>Попробовать</button>
									<div className={cx('item-list')}>
										{item.pluses.length &&
											item.pluses.map((plus) => (
												<div
													key={`plus-${plus.title}`}
													className={cx(`${plus.isCross ? 'cross' : ''}`)}
												>
													{!plus.isCross ? (
														<svg
															width='32'
															height='32'
															viewBox='0 0 32 32'
															fill='none'
															xmlns='http://www.w3.org/2000/svg'
														>
															<path
																d='M9 16.2882L14.6027 22L23 12'
																stroke='#39D292'
																strokeWidth='3'
																strokeLinecap='round'
																strokeLinejoin='round'
															/>
														</svg>
													) : (
														<svg
															width='32'
															height='32'
															viewBox='0 0 32 32'
															fill='none'
															xmlns='http://www.w3.org/2000/svg'
														>
															<path
																d='M21 22L16 17L11 22'
																stroke='#737373'
																strokeWidth='3'
																strokeLinecap='round'
																strokeLinejoin='round'
															/>
															<path
																d='M11 12L16 17L21 12'
																stroke='#737373'
																strokeWidth='3'
																strokeLinecap='round'
																strokeLinejoin='round'
															/>
														</svg>
													)}
													<span>{plus.title}</span>
												</div>
											))}
									</div>
								</div>
							)
						})}
				</div>
			</div>
		</Container>
	)
}

export default License
