import { FC } from 'react'
import classNamesBind from 'classnames/bind'

import { FooterProps } from './Footer.d'

import styles from './Footer.module.scss'
import { Container } from '../Container'
import { Link } from 'react-router-dom'

import { footerMenu } from '../../../assets/mocs'
import { Logo } from '../../UI'

const cx = classNamesBind.bind(styles)

const Footer: FC<FooterProps.Props> = (props) => {
	const { className } = props

	return (
		<div className={cx('wrapper')}>
			<Container className={cx('container', className)}>
				<Logo className={cx('logo')} />
				<ul className={cx('menu')}>
					{footerMenu.map((item, i) => (
						<li key={`footer-menu-item-${item.id ? item.id : i}`}>
							{item.otherPage ? (
								<a
									target='_blank'
									rel='noopener noreferrer'
									href={item.link}
								>
									{item.name}
								</a>
							) : (
								<Link
									target='_blank'
									rel='noopener noreferrer'
									to={item.link}
								>
									{item.name}
								</Link>
							)}
						</li>
					))}
				</ul>
			</Container>
		</div>
	)
}

export default Footer
