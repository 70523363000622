import { FC, useContext, useEffect } from 'react'
import classNamesBind from 'classnames/bind'

import { TextPageProps } from './TextPage.d'

import styles from './TextPage.module.scss'
import { ModalContext } from '../../contexts'

const cx = classNamesBind.bind(styles)

const TextPage: FC<TextPageProps.Props> = (props) => {
	const { text } = props

	const { closeModal } = useContext(ModalContext)

	useEffect(() => {
		closeModal()
	}, [])

	return <div className={cx('wrapper')}>{text}</div>
}

export default TextPage
