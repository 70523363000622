import { IHow } from './../../types/IHow'

import item1 from '../images/how/item1.gif'
import item2 from '../images/how/item2.gif'
import item3 from '../images/how/item3.gif'
import item4 from '../images/how/item4.gif'

export const how: IHow[] = [
	{
		id: 0,
		title: 'Создавайте фишинговые  рассылки',
		text: 'Встроенный в систему фишинговый модуль с редактором проверяет, как поведут себя сотрудники при реальной атаке',
		image: item1,
		color: '#D4C1FB',
	},
	{
		id: 1,
		title: 'Обучайте сотрудников',
		text: 'Материалы и набор теоретических блоков — всё необходимое для обучения',
		image: item2,
		color: '#BEFAB4',
	},
	{
		id: 2,
		title: 'Отслеживайте статистику',
		text: 'Подробный анализ текущего уровня осведомленности сотрудников после рассылок и обучения',
		image: item3,
		color: '#F6C4A4',
	},
	{
		id: 3,
		title: 'Кастомизируйте',
		text: 'Гибкие настройки платформы позволяют изменять систему под ваши потребности',
		image: item4,
		color: '#A1A9F9',
	},
]
