import { FC, useEffect, useState } from 'react'
import classNamesBind from 'classnames/bind'

import { NewsProps } from './News.d'

import styles from './News.module.scss'
import { NewsItem } from './NewsItem'
import { NotNews } from './NotNews'

const cx = classNamesBind.bind(styles)

const News: FC<NewsProps.Props> = (props) => {
	const { title, items, hasScroll = false } = props

	const [height, setHeight] = useState(200)
	const [endNews, setEndNews] = useState(false)
	const [lastNewsIndex, setLastNewsIndex] = useState(3)

	useEffect(() => {
		if (height < 150 && !endNews) {
			setLastNewsIndex((prev) => prev + 3)
			setEndNews(items.length < lastNewsIndex)
		}
	}, [height])

	const scrollHandler = (e: any) => {
		const height =
			e.target.documentElement.scrollHeight -
			(e.target.documentElement.scrollTop + window.innerHeight)

		setHeight(height)
	}

	useEffect(() => {
		if (hasScroll) {
			document.addEventListener('scroll', scrollHandler)
		}
		return () => {
			if (hasScroll) {
				document.removeEventListener('scroll', scrollHandler)
			}
		}
	}, [])

	return (
		<div className={cx('wrapper')}>
			<div className={cx('title')}>{items.length ? title : 'Скоро здесь будут новости'}</div>
			{items.length ? (
				<div className={cx('inner')}>
					{items.map(
						(item, i) =>
							i < lastNewsIndex && (
								<NewsItem
									key={`news-${item.id ? item.id : i}`}
									info={item}
								/>
							),
					)}
				</div>
			) : (
				<NotNews />
			)}
		</div>
	)
}

export default News
