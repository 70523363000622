import { FC } from 'react'
import classNamesBind from 'classnames/bind'

import { NotVacancyProps } from './NotVacancy.d'

import styles from './NotVacancy.module.scss'
import sadSmile from '../../../../assets/images/sadsmile.png'

const cx = classNamesBind.bind(styles)

const NotVacancy: FC<NotVacancyProps.Props> = () => {
	return (
		<>
			<img
				alt='Грустный смайлик'
				src={sadSmile}
				className={cx('smile')}
			/>
			<div className={cx('text')}>
				но если вы очень хотите у нас работать, отправьте резюме на нашу почту
				<a href='mailto:info@secure-t.ru'>info@secure-t.ru</a>
			</div>
		</>
	)
}

export default NotVacancy
