import { FC } from 'react'
import classNamesBind from 'classnames/bind'

import { AboutPageProps } from './AboutPage.d'

import styles from './AboutPage.module.scss'
import { Container, News } from '../../components/common'
import { news } from '../../assets/mocs'

const cx = classNamesBind.bind(styles)

const AboutPage: FC<AboutPageProps.Props> = () => {
	return (
		<div className={cx('wrapper')}>
			<Container className={cx('container')}>
				<Container className={cx('text-container')}>
					<div className={cx('title')}>с 2019 года</div>
					<div className={cx('text')}>
						Удовлетворяем запросы и решаем проблемы безопасности даже самого требовательного
						клиента. Мы стараемся построить эффективную систему информационной безопасности для
						каждой заинтересованной компании.
					</div>
				</Container>
				<News
					title='Последние новости'
					items={news}
					hasScroll={true}
				/>
			</Container>
		</div>
	)
}

export default AboutPage
