import { FC, useContext, useEffect, useRef, useState } from 'react'
import { HashLink } from 'react-router-hash-link'
import classNamesBind from 'classnames/bind'

import { HeaderProps } from './Header.d'
import { headerMenu } from '../../../assets/mocs'

import { Container } from '../Container'
import { Logo } from '../../UI'

import styles from './Header.module.scss'
import { ModalContext } from '../../../contexts'
import { useLocation } from 'react-router-dom'

const cx = classNamesBind.bind(styles)

const Header: FC<HeaderProps.Props> = (props) => {
	const { className, isFixed = false } = props

	const { openModal } = useContext(ModalContext)
	const wrapper = useRef<HTMLDivElement>(null)

	const [showMenu, setShowMenu] = useState(false)
	const [height, setHeight] = useState(0)

	const handleClick = () => {
		if (showMenu) {
			setShowMenu(false)
			document.body.removeAttribute('style')
		} else {
			setShowMenu(true)
			document.body.setAttribute('style', 'overflow-y: hidden;')
		}
	}

	const handleModal = () => {
		openModal('null')
	}

	const handleResize = () => {
		if (showMenu && wrapper.current) {
			setHeight(window.innerHeight)
		}
	}

	useEffect(() => {
		handleResize()
		window.addEventListener('resize', handleResize)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [showMenu])

	return (
		<>
			<div className={cx('wrapper', `${isFixed ? '' : 'fixed'}`)}>
				<Container className={cx('container', className)}>
					<Logo className={cx('logo')} />
					<div
						ref={wrapper}
						className={cx('menu-wrapper', `${showMenu ? 'active' : ''}`)}
						style={showMenu ? { height: height + 'px' } : {}}
					>
						<ul className={cx('menu')}>
							{headerMenu.map((item, i) => (
								<li key={`footer-menu-item-${item.id ? item.id : i}`}>
									<HashLink
										smooth
										to={item.link}
										onClick={() => {
											setShowMenu(false)
											document.body.removeAttribute('style')
										}}
									>
										{item.name}
									</HashLink>
								</li>
							))}
						</ul>
						<div>
							<button
								className={cx('button', 'button--try')}
								onClick={() => handleModal()}
							>
								Попробовать
								<svg
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<g clipPath='url(#clip0_201_567)'>
										<path
											d='M11.3714 10.0716C11 9.92299 10.5758 10.0101 10.2929 10.2929C10.0101 10.5758 9.92299 11 10.0716 11.3714L14.0715 21.3714C14.2178 21.737 14.565 21.9828 14.9585 21.9991C15.3519 22.0155 15.7183 21.7994 15.8944 21.4472L17.274 18.6882L20.2929 21.7071C20.6834 22.0976 21.3166 22.0976 21.7071 21.7071C22.0976 21.3166 22.0976 20.6834 21.7071 20.2929L18.6882 17.274L21.4472 15.8944C21.7994 15.7183 22.0155 15.3519 21.9991 14.9585C21.9828 14.565 21.737 14.2178 21.3714 14.0715L11.3714 10.0716Z'
											fill='white'
										/>
										<rect
											x='16.0469'
											y='14.445'
											width='2.01599'
											height='5.03998'
											rx='1.008'
											transform='rotate(137.691 16.0469 14.445)'
											fill='white'
										/>
										<rect
											x='12.6953'
											y='8.33234'
											width='2'
											height='5'
											rx='1'
											transform='rotate(-140.744 12.6953 8.33234)'
											fill='white'
										/>
										<rect
											x='10.1016'
											y='6.86664'
											width='2'
											height='5'
											rx='1'
											transform='rotate(163.036 10.1016 6.86664)'
											fill='white'
										/>
										<rect
											x='7.4375'
											y='8.21161'
											width='2'
											height='5'
											rx='1'
											transform='rotate(106.541 7.4375 8.21161)'
											fill='white'
										/>
										<rect
											x='7.07422'
											y='11.1688'
											width='2'
											height='5'
											rx='1'
											transform='rotate(50.3027 7.07422 11.1688)'
											fill='white'
										/>
									</g>
									<defs>
										<clipPath id='clip0_201_567'>
											<rect
												width='24'
												height='24'
												fill='white'
											/>
										</clipPath>
									</defs>
								</svg>
							</button>
							<a
								target='_blank'
								rel='noopener noreferrer'
								href='https://edu.secure-t.ru/lk'
								className={cx('button', 'button--login')}
							>
								Войти
								<svg
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M17.7545 13.9999C18.9966 13.9999 20.0034 15.0068 20.0034 16.2488V17.1673C20.0034 17.7406 19.8242 18.2997 19.4908 18.7662C17.9449 20.9294 15.4206 22.0011 12.0004 22.0011C8.5794 22.0011 6.05643 20.9289 4.51427 18.7646C4.18231 18.2987 4.00391 17.7409 4.00391 17.1688V16.2488C4.00391 15.0068 5.01076 13.9999 6.25278 13.9999C7.4948 13.9999 9.67936 15 12.0004 15C14.3214 15 16.5125 13.9999 17.7545 13.9999ZM12.0004 2.00464C14.7618 2.00464 17.0004 4.24321 17.0004 7.00464C17.0004 9.76606 14.7618 12.0046 12.0004 12.0046C9.23894 12.0046 7.00036 9.76606 7.00036 7.00464C7.00036 4.24321 9.23894 2.00464 12.0004 2.00464Z'
										fill='#323037'
									/>
								</svg>
							</a>
						</div>
					</div>
					<div
						className={cx('burger-menu', `${showMenu ? 'active' : ''}`)}
						onClick={() => handleClick()}
					>
						<span></span>
					</div>
					{showMenu && <div className={cx('blur')}></div>}
				</Container>
			</div>
			{showMenu && <div className={cx('blur')}></div>}
		</>
	)
}

export default Header
