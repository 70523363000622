import { FC, useEffect, useMemo, useState } from 'react'
import classNamesBind from 'classnames/bind'
import { Outlet, useLocation } from 'react-router-dom'

import { MainLayoutProps } from './MainLayout.d'

import { Footer, Header, Modals, ThanksModal } from '../../common'

import styles from './MainLayout.module.scss'
import { IModalContext, ModalProvider } from '../../../contexts'

const cx = classNamesBind.bind(styles)

const MainLayout: FC<MainLayoutProps.Props> = () => {
	const [RequestModalOpen, setRequestModalOpen] = useState<string | null>(null)
	const [thanksModal, setThanksModal] = useState<boolean>(false)
	const [isFixed, setIsFixed] = useState(false)
	const { pathname } = useLocation()

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [pathname])

	const modalContext: IModalContext = useMemo(
		() => ({
			openModal: (title: string) => {
				setRequestModalOpen(title)
			},
			closeModal: () => {
				setRequestModalOpen(null)
			},
			openThanks: () => {
				setThanksModal(true)
			},
			closeThanks: () => {
				setThanksModal(false)
			},
		}),
		[],
	)

	const handleScroll = (event: Event) => {
		if (
			(document.documentElement && document.documentElement.scrollTop) < 30 &&
			(document.body && document.body.scrollTop) < 30
		) {
			setIsFixed(false)
		} else if (
			(document.documentElement && document.documentElement.scrollTop) >= 30 ||
			(document.body && document.body.scrollTop) >= 30
		) {
			setIsFixed(true)
		}
	}

	useEffect(() => {
		document.addEventListener('scroll', handleScroll)
		return () => {
			document.removeEventListener('scroll', handleScroll)
		}
	}, [])

	return (
		<ModalProvider value={modalContext}>
			<div className={cx('wrapper', `${isFixed ? 'fixed' : ''}`)}>
				<Header isFixed={isFixed} />
				<Outlet />
				<Footer />
				<Modals
					isShow={!!RequestModalOpen}
					type={RequestModalOpen}
				/>
				<ThanksModal isShow={!!thanksModal} />
			</div>
		</ModalProvider>
	)
}

export default MainLayout
