import item1 from './item1.png'
import item2 from './item2.png'
import item3 from './item3.png'
import item4 from './item4.png'
import item5 from './item5.png'
import item6 from './item6.png'
import item7 from './item7.png'
import item8 from './item8.png'
import item9 from './item9.png'
import item10 from './item10.png'
import item11 from './item11.png'
import item12 from './item12.png'
import item13 from './item13.png'
import item14 from './item14.png'

export type IName =
	| 'item1'
	| 'item2'
	| 'item3'
	| 'item4'
	| 'item5'
	| 'item6'
	| 'item7'
	| 'item8'
	| 'item9'
	| 'item10'
	| 'item11'
	| 'item12'
	| 'item13'
	| 'item14'

export const itemsPng = [
	{
		path: item1,
		width: 128,
		height: 54,
	},
	{
		path: item2,
		width: 174,
		height: 54,
	},
	{
		path: item3,
		width: 150,
		height: 54,
	},
	{
		path: item4,
		width: 197,
		height: 54,
	},
	{
		path: item5,
		width: 200,
		height: 54,
	},
	{
		path: item6,
		width: 189,
		height: 54,
	},
	{
		path: item7,
		width: 175,
		height: 54,
	},
	{
		path: item8,
		width: 191,
		height: 54,
	},
	{
		path: item9,
		width: 147,
		height: 54,
	},
	{
		path: item10,
		width: 220,
		height: 54,
	},
	{
		path: item11,
		width: 126,
		height: 54,
	},
	{
		path: item12,
		width: 170,
		height: 54,
	},
	{
		path: item13,
		width: 142,
		height: 54,
	},
	{
		path: item14,
		width: 126,
		height: 54,
	},
]
