import { FC } from 'react'
import classNamesBind from 'classnames/bind'

import { VacancyProps } from './Vacancy.d'

import styles from './Vacancy.module.scss'

const cx = classNamesBind.bind(styles)

const Vacancy: FC<VacancyProps.Props> = (props) => {
	const { info } = props

	return (
		<div className={cx('wrapper')}>
			<div className={cx('title')}>{info.title}</div>
			<div className={cx('stacks')}>
				{info.stacks.length &&
					info.stacks.map((item, i) => (
						<div
							key={`vacancy-${info.id}-${i}`}
							className={cx('itemWrapper')}
						>
							<div className={cx('item')}>{item}</div>
							{i === 0 && info.image && (
								<img
									alt=''
									src={info.image}
								/>
							)}
						</div>
					))}
			</div>
		</div>
	)
}

export default Vacancy
