import { FC } from 'react'
import classNamesBind from 'classnames/bind'

import { TrainProps } from './Train.d'

import styles from './Train.module.scss'
import { Container } from '../Container'
import { Rewiews } from './Rewiews'
import { Slider } from './Slider'
import { partnersDown, partnersUp } from '../../../assets/mocs/partner'

const cx = classNamesBind.bind(styles)

const Train: FC<TrainProps.Props> = () => {
	return (
		<Container className={cx('wrapper-wrapper')}>
			<Container className={cx('wrapper')}>
				<div className={cx('title')}>
					Обучаем более
					<br />
					<span>150 000</span>сотрудников
					<br />
					в более чем <span>100 организаций</span>
				</div>

				<Slider
					className={cx('slider-up')}
					sliderItemClassname={cx('slider-item')}
					items={partnersUp}
					isFullWidth
					settings={{
						arrows: false,
						dots: false,
						infinite: true,
						speed: 500,
						slidesToShow: 1,
						slidesToScroll: 1,
						swipeToSlide: true,
						autoplay: true,
						variableWidth: true,
						pauseOnHover: true,
					}}
				/>
				<Rewiews />
				<Slider
					className={cx('slider-up', 'slider-down')}
					title='Партнёры'
					isFullWidth
					items={partnersDown}
					settings={{
						arrows: false,
						dots: false,
						infinite: true,
						speed: 500,
						slidesToShow: 1,
						slidesToScroll: 1,
						swipeToSlide: true,
						autoplay: true,
						variableWidth: true,
						pauseOnHover: true,
					}}
				/>
			</Container>
		</Container>
	)
}

export default Train
