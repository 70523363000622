import { ID, UUID } from '../../types/ID'

interface IMenuItem {
	id?: UUID | ID
	name: string
	link: string
}

export const headerMenu: IMenuItem[] = [
	{
		name: 'Продукт',
		link: '/#product',
	},
	{
		name: 'Стоимость',
		link: '/#licenses',
	},
	{
		name: 'О нас',
		link: '/about',
	},
	{
		name: 'Вакансии',
		link: '/vacancy',
	},
]
