import { FC } from 'react'
import classNamesBind from 'classnames/bind'

import { ContainerProps } from './Container.d'

import styles from './Container.module.scss'

const cx = classNamesBind.bind(styles)

const Container: FC<ContainerProps.Props> = (props) => {
	const { className, children } = props

	return <div className={cx('container', className)}>{children}</div>
}

export default Container
