import { ILicense } from './../../types/ILicense'

export const licenses: ILicense[] = [
	{
		id: 0,
		title: 'Фишинг',
		subtitle: 'Проверяйте своих сотрудников на противодействие фишинговым атакам ',
		isDark: false,
		price: 'Совсем недорого',
		priceFor: 'За сотрудника в год',
		pluses: [
			{
				title: 'Имитация фишинга',
				isCross: false,
			},
			{
				title: 'Редактор шаблонов для рассылки',
				isCross: false,
			},
			{
				title: 'Обучающий модуль',
				isCross: true,
			},
			{
				title: 'Адаптация решения под нужды компании',
				isCross: true,
			},
		],
	},
	{
		id: 1,
		title: 'Полная',
		subtitle: 'Используйте весь функционал нашей платформы для обучения сотрудников',
		isDark: false,
		price: 'Недорого',
		priceFor: 'За сотрудника в год',
		pluses: [
			{
				title: 'Имитация фишинга',
				isCross: false,
			},
			{
				title: 'Редактор шаблонов для рассылки',
				isCross: false,
			},
			{
				title: 'Система дистанционного обучения',
				isCross: false,
			},
			{
				title: 'Уникальные курсы для обучения сотрудников',
				isCross: false,
			},
			{
				title: 'Адаптация решения под нужды компании',
				isCross: true,
			},
		],
	},
	{
		id: 2,
		title: 'Enterprise +',
		subtitle: 'Полная кастомизация системы под любые требования клиента',
		isDark: true,
		price: 'Индивидуальная',
		priceFor: 'От тысячи сотрудников',
		pluses: [
			{
				title: 'Учитывание ваших потребностей',
				isCross: false,
			},
			{
				title: 'Настройка платформы под ваши требования',
				isCross: false,
			},
			{
				title: 'Все преимущества полной лицензии',
				isCross: false,
			},
		],
	},
]
