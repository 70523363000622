const API_HOST = process.env.REACT_APP_API_HOST || ''

export class API {
	static request(url: string, data: FormData | object | null = null) {
		return new Promise((res, rej) => {
			const headers = new Headers()
			let body
			if (data instanceof FormData) {
				// https://stackoverflow.com/a/49510941
				// headers.append('Content-Type', 'multipart/form-data')
				body = data
			} else {
				headers.append('Content-Type', 'application/json')
				body = JSON.stringify(data)
			}

			fetch(API_HOST + url, {
				method: 'POST',
				headers,
				body,
			})
				.then((res) => {
					if (!res.ok) rej(res.statusText)
					else return res.text()
					return
				})
				.then((res) => (res ? JSON.parse(res || '') : {}))
				.then((r) => res(r))
				.catch((err) => rej(err))
		})
	}

	static Feedback(name: string, type: string, phone: string, email: string) {
		return this.request('/v1/request_feedback/', {
			name,
			type,
			phone,
			email,
		})
	}

	static Apply({
		name,
		contact,
		details,
		file,
		job_name,
	}: {
		name: string
		contact: string
		details: string
		file: File
		job_name: string
	}) {
		const form = new FormData()

		form.append('name', name)
		form.append('contact', contact)
		form.append('details', details)
		form.append('file', file)
		form.append('job_name', job_name)

		console.log({
			name,
			contact,
			details,
			file,
			job_name,
		})

		return this.request('/v1/job-application/', form)
	}
}
