import { createContext } from 'react'

export interface IModalContext {
	openModal: (title: string) => void
	closeModal: () => void
	openThanks: () => void
	closeThanks: () => void
}

class ModalContextDefault implements IModalContext {
	openModal = () => {}

	closeModal = () => {}

	openThanks = () => {}

	closeThanks = () => {}
}

export const ModalContext = createContext<IModalContext>(new ModalContextDefault())

export const ModalProvider = ModalContext.Provider
