import { FC } from 'react'
import classNamesBind from 'classnames/bind'

import { LawsProps } from './Laws.d'

import styles from './Laws.module.scss'
import { Container } from '../Container'
import { laws } from '../../../assets/mocs/laws'

const cx = classNamesBind.bind(styles)

const Laws: FC<LawsProps.Props> = () => {
	return (
		<Container className={cx('wrapper')}>
			<Container className={cx('title')}>Помогаем соответствовать законодательству</Container>
			<div className={cx('subtitle')}>Законы, обязующие обучать ваших сотрудников</div>
			{laws.length &&
				laws.map((item) => {
					return (
						<div
							key={`law-${item.id}`}
							className={cx('item')}
						>
							<>
								<span>{item.title}</span>
								{item.image}
							</>
						</div>
					)
				})}
		</Container>
	)
}

export default Laws
