import { IPropblem } from './../../types/IProblem'

import item1 from '../images/problems/item1.png'
import item2 from '../images/problems/item2.png'
import item3 from '../images/problems/item3.png'

export const problems: IPropblem[] = [
	{
		id: 0,
		title: 'Отсутствие цифровой гигиены',
		text: 'Большинство сотрудников не знают основ цифровой гигиены, небрежно относятся к информационной безопасности ',
		image: item1,
	},
	{
		id: 1,
		title: 'Нет способа проверить сотрудников',
		text: 'Компаниям не хватает инструмента, с помощью которого можно проверять сотрудников в ситуациях, близких к реальной атаке',
		image: item2,
	},
	{
		id: 2,
		title: 'Отсутствие статистики уязвимостей',
		text: 'Менеджеры не располагают статистикой об уровне подготовки сотрудников и степени их уязвимости ',
		image: item3,
	},
]
