import { FC, useEffect } from 'react'
import classNamesBind from 'classnames/bind'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { NotFoundPageProps } from './NotFoundPage.d'

import { Container } from '../../components/common'

import styles from './NotFoundPage.module.scss'

const cx = classNamesBind.bind(styles)

const NotFoundPage: FC<NotFoundPageProps.Props> = () => {
	const locate = useLocation()
	const navigate = useNavigate()

	useEffect(() => {
		if (locate.pathname !== '/404') {
			navigate('/404')
		}
	}, [])

	return (
		<div className={cx('wrapper')}>
			<Container className={cx('container')}>
				<h1 className={cx('title')}>404</h1>
				<div className={cx('text')}>
					Страница не найдена. <br />
					Можете вернуться на <Link to='/'>главную страницу</Link>
				</div>
			</Container>
		</div>
	)
}

export default NotFoundPage
