import { INewsItem } from '../../types/INews'

import news0Item1 from './../images/news/news0Item1.png'
import news0Item2 from './../images/news/news0Item2.png'
import news0Item3 from './../images/news/news0Item3.jpeg'
import news1Item1 from './../images/news/news1Item1.png'
import EducateTImage from './../images/news/educate-t.png'

export const news: INewsItem[] = [
	{
		id: 0,
		status: 'Блог',
		title: 'История создания компании Secure-T',
		// secondTitle: 'История создания компании Secure-T',
		image: news0Item1,
		text: `
		Известно, что самым слабым звеном в системе безопасности компании является человек. И какая бы дорогая и непробиваемая защита ни была установлена в компании, один невнимательный и неосведомлённый пользователь может всё разрушить. Согласно разным статистическим данным, совокупный объём данных, скомпрометированных в результате внутренних утечек в 2020 году, составил 11 млрд записей.
		<img src='${news0Item3}' alt='' />Чтобы обеспечить безопасность своих данных, многие компании начали обучать своих сотрудников основам информационной безопасности. Однако не всегда было понятно, как эффективно выстроить этот процесс. Со временем появились различные курсы по обучению, задача которых повысить осведомлённость сотрудников в области информационной безопасности.
		<br>Изучая конкурентов, мы пришли к выводу, что на российском рынке практически нет продуктов, способных адекватно противостоять современным угрозам. Поэтому было принято решение разработать собственный и уникальный продукт — Security Awareness Platforms, который избавляет компании от необходимости создания собственных курсов и закупки услуг по проверке уровня уязвимости сотрудников к методам социальной инженерии.
		<br>1 октября 2019 года был первый официальный рабочий день компании Secure-T, которая была создана двумя выпускниками Московского государственного университета им. М. В. Ломоносова — Шаховым Сергеем и Никишкиным Харитоном. До этого Сергей несколько лет работал специалистом по информационной безопасности в крупных российских компаниях, а Харитон занимался небольшим стартапом в сфере аналитике данных.
		<br>Первая версия Secure-T Awareness Platform вышла на рынок в июле 2020 года, которая объединяла в себе базовые курсы по информационной безопасности и ограниченный фишинговый модуль для проверки уровня уязвимости персонала. Продукт вызвал интерес у нескольких компаний, которые искали быстрый и эффективный способ улучшения уровня информационной безопасности своих сотрудников. В результате чего, под конец 2020 года Secure-T Awareness Platform насчитывала уже 5 000 пользователей.
		<br>За три года Secure-T успела зарекомендовать себя как надёжный партнёр в области информационной безопасности, что позволило привлечь внимание компаний из разных сфер деятельности. Благодаря этому за 2021 год мы подключили ещё 13 000 пользователей, а к концу 2022 года с помощью Secure-T Awareness Platform обучались уже 50 000 пользователей.
		<img src='${news0Item2}' alt='' />В результате активного применения нашей платформы компании смогли повысить уровень безопасности среди сотрудников. А некоторым клиентам даже удалось предотвратить потенциальные угрозы, которые были обнаружены после проверки уязвимости персонала.
		<br>В наше время информационные технологии играют ключевую роль в развитии бизнеса и общества. Поэтому мы делаем наш продукт доступным и понятным для всех пользователей, независимо от их уровня компетентности в области информационных технологий. Мы прислушиваемся к пожеланиям наших клиентов и реагируем на их потребности, чтобы сделать продукт ещё более полезным.
		`,
	},
	{
		id: 1,
		status: 'Новость',
		title: 'A1 и Secure-T запустили совместный сервис в Республике Беларусь!',
		// secondTitle: 'История создания компании Secure-T',
		image: news1Item1,
		text: `
		Мы рады объявить о <a href='https://a1data.by/news/novaya-platforma-security-awareness-ot-a1-investitsiya-v-razvitie-sotrudnikov-v-oblasti-informatsion/' target="_blank">запуске нашего сервиса</a> в партнерстве с инновационным лидером в сфере телекоммуникаций — A1! Платформа «Security Awareness» — это уникальное решение, которое призвано укрепить кибербезопасность вашего бизнеса. Это не просто сервис, это инвестиция в безопасное будущее вашей компании.

		<b>Что включено в «Security Awareness»:</b>
		• Базовые курсы по кибербезопасности.
		• Тестирование и аналитика.
		• Модуль для имитации фишинговых рассылок.
		
		<b>Особенности сервиса:</b>
		На платформе «Security Awareness» каждый заказчик может самостоятельно управлять образовательным процессом: создавать и редактировать текущие курсы, а также шаблоны фишинга. Вы можете формировать группы пользователей, настраивая индивидуальные правила для них, регулировать периодичность обучения по выбранным курсам и частоту фишинговых рассылок в соответствии с внутренней политикой безопасности компании.
		
		<b>Индивидуальные решения под любые потребности:</b>
		• <b>Security Awareness:</b> Обучение и фишинговые рассылки.
		• <b>Security Awareness Phishing:</b> Только фишинговые рассылки.
		• <b>Security Awareness Phishing Light:</b> Разовая фишинговая рассылка.
		
		<b>Безопасность превыше всего:</b>
		Платформа размещена в аттестованном сегменте дата-центре A1 класса TIER III, который является одним из крупнейших ЦОД на территории Республики Беларусь и отвечает высоким требованиям безопасности с уровнем доступности сервисов 99,982%.
		
		<b>Узнайте больше: </b>
		С подробностями нового сервиса можно ознакомиться по <a href='https://a1data.by/secure-t/' target="_blank">ссылке</a>.`,
	},
	{
		id: 2,
		status: 'Онлайн платформа',
		title: 'Представляем Educate-T: Новые горизонты корпоративного обучения информационной безопасности и цифровой грамотности',
		// secondTitle: 'История создания компании Secure-T',
		image: EducateTImage,
		text: `<b>Представляем Educate-T: Новые горизонты корпоративного обучения информационной безопасности и цифровой грамотности</b>

	Мы рады объявить о полноценном запуске Educate-T - долгожданного дополнения к нашей образовательной платформе <a href='https://secure-t.ru/' target="_blank">Secure-T</a>.

	<a href='https://educate-t.ru/' target="_blank">Educate-T</a> предлагает к приобретению широкий набор продвинутых курсов по информационной безопасности, от компаний лидеров рынка.

	<b>С Educate-T можно:</b>

	1. Расширить базу знаний в своей компании. Погрузитесь глубже в сложные темы ИБ и ИТ с помощью продвинутых курсов, охватывающих самые разные темы: от кибербезопасности до языков программирования и анализа данных.
	2. Обучаться по программе передовых компаний и вузов. Контент от ведущих специалистов, известных своим опытом. Большинство курсов ориентировано на практические знания, которые можно напрямую применить к проектам и инициативам вашей организации.

	<b>Интеграция с Secure-T</b>

	В случае наличия активной подписки на платформе <a href='https://secure-t.ru/' target="_blank">Secure-T</a>, при покупке курса на Educate-T он автоматически добавится в ваш личный кабинет.

	<b>Доступные курсы</b>

	На нашей платформе вы сможете найти курсы от таких организаций, как Positive Technologies, CyberED, МФТИ Физтех, КОД ИБ Академия и конечно же Secure-T. В настоящий момент бесплатно доступны: “Кибербезопасность: первое погружение” от Positive Technologies (~1,5ч) и “Профессия — Белый Хакер” от CyberED (~9ч).

	<b>Сделайте следующий шаг</b>

	Готовы вывести обучение в вашей организации на новый уровень? Посетите <a href='https://educate-t.ru/' target="_blank">Educate-T</a> сегодня, чтобы ознакомиться с доступными материалами!`,
	},
]
