import { FC } from 'react'
import classNamesBind from 'classnames/bind'

import { NotNewsProps } from './NotNews.d'

import styles from './NotNews.module.scss'

import papper from '../../../../assets/images/papper.png'

const cx = classNamesBind.bind(styles)

const NotNews: FC<NotNewsProps.Props> = () => {
	return (
		<img
			alt='Газета'
			src={papper}
			className={cx('papper')}
		/>
	)
}

export default NotNews
