import { FC } from 'react'
import classNamesBind from 'classnames/bind'

import { HowsProps } from './Hows.d'

import styles from './Hows.module.scss'
import { Container } from '../Container'
import { how } from '../../../assets/mocs/how'

const cx = classNamesBind.bind(styles)

const Hows: FC<HowsProps.Props> = () => {
	return (
		<Container className={cx('wrapper', 'container')}>
			<div
				className={cx('title')}
				id='product'
			>
				Как работает платформа
			</div>
			{/* <div className={cx('subtitle')}>Наше решение Secure-T Awareness Platform</div> */}
			{how.length &&
				how.map((item) => {
					return (
						<div
							key={`problem-${item.id}`}
							className={cx('item')}
						>
							<div className={cx('text-wrapper')}>
								<div className={cx('item-title')}>{item.title}</div>
								<div className={cx('item-text')}>{item.text}</div>
							</div>
							<div
								className={cx('image-wrapper')}
								style={{ background: item.color }}
							>
								<img
									alt=''
									src={item.image}
								/>
							</div>
						</div>
					)
				})}
		</Container>
	)
}

export default Hows
