import classNamesBind from 'classnames/bind'
import { Container } from '../../components/common'

import styles from './TextPage.module.scss'

const cx = classNamesBind.bind(styles)

export const privacyText = (
	<Container className={cx('container')}>
		<div className={cx('title')}>Политика в отношении обработки персональных данных ООО «Секьюр-Ти» </div>
		<div className={cx('blocks')}>
			<div className={cx('block')}>
				<div className={cx('blockTitle', 'text')}>1. Общие положения</div>
				<div className={cx('blockText', 'text')}>
					Настоящая политика обработки персональных данных составлена в соответствии с требованиями
					Федерального закона от 27.07.2006. №152-ФЗ «О персональных данных» и определяет порядок
					обработки персональных данных и меры по обеспечению безопасности персональных данных,
					предпринимаемые ООО "Секьюр-Ти" (далее – Оператор).
				</div>
				<div className={cx('rules')}>
					<div className={cx('rule', 'text')}>
						<span>1.1</span> Политика общества с ограниченной ответственностью «Секьюр-Ти» (далее – Общество) в отношении обработки персональных данных (далее – Политика) разработана в соответствии с Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных данных» в целях обеспечения защиты прав и свобод субъекта персональных данных при обработке его персональных данных.
					</div>
					<div className={cx('rule', 'text')}>
						<span>1.2</span> Действие Политики распространяется в отношении всех работников Общества и всех ПДн, которые обрабатывает Общество.
					</div>
					<div className={cx('rule', 'text')}>
						<span>1.3</span> В целях настоящей Политики в ней используются следующие сокращения, термины и определения:
					</div>
					<div className={cx('rules')}>
						<div className={cx('rule', 'text')}>
							<span>152-ФЗ -</span> Федеральный закон от 27 июля 2006 г. № 152-ФЗ «О персональных данных»
						</div>
						<div className={cx('rule', 'text')}>
							<span>Автоматизированная обработка ПДн  -</span> обработка ПДн с помощью средств вычислительной техники
						</div>
						<div className={cx('rule', 'text')}>
							<span>Инцидент ИБ -</span> неправомерная или случайная передача (предоставление, распространение, доступ) ПДн, повлекшая нарушение прав субъектов ПДн
						</div>
						<div className={cx('rule', 'text')}>
							<span>ИСПДн -</span> информационная система ПДн
						</div>
						<div className={cx('rule', 'text')}>
							<span>Обработка ПДн -</span> любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с ПДн, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение ПДн
						</div>
						<div className={cx('rule', 'text')}>
							<span>Общество -</span> общество с ограниченной ответственностью «Секьюр-Ти»
						</div>
						<div className={cx('rule', 'text')}>
							<span>Ответственный за обработку ПДн -</span> работник, назначенный ответственным за организацию обработки ПДн в Обществе на основании Приказа генерального директора
						</div>
						<div className={cx('rule', 'text')}>
							<span>ПДн -</span> персональные данные – любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту ПДн)
						</div>
						<div className={cx('rule', 'text')}>
							<span>Работник -</span> физическое лицо, состоящее в трудовых отношениях с Обществом
						</div>
						<div className={cx('rule', 'text')}>
							<span>Сайты -</span> информационные ресурсы в телекоммуникационной сети Интернет, расположенные по адресу: <a href='https://secure-t.ru/' target='_blank' rel='noreferrer'>https://secure-t.ru/</a>, <a href='https://educate-t.ru/' target='_blank' rel='noreferrer'>https://educate-t.ru/</a>
						</div>
						<div className={cx('rule', 'text')}>
							<span>Роскомнадзор -</span> Федеральная служба по надзору в сфере связи, информационных технологий и массовых коммуникаций
						</div>
					</div>
					<div className={cx('blockText', 'text')}>
						Все остальные термины и определения используются в значении, обозначенном 152-ФЗ.
					</div>
					<div className={cx('rule', 'text')}>
						<span>1.4</span> При обработке ПДн Общество придерживается следующих принципов:
						<div className={cx('rules')}>
							<div className={cx('rule', 'text')}>
								<span>-</span> обработка ПДн должна осуществляться на законной и справедливой основе;
							</div>
							<div className={cx('rule', 'text')}>
								<span>-</span> обработка ПДн должна ограничиваться достижением конкретных, заранее определенных и законных целей. Не допускается обработка ПДн, несовместимая с целями их сбора;
							</div>
							<div className={cx('rule', 'text')}>
								<span>-</span> не допускается объединение баз данных, содержащих ПДн, обработка которых осуществляется в целях, несовместимых между собой;
							</div>
							<div className={cx('rule', 'text')}>
								<span>-</span> обработке подлежат только ПДн, которые отвечают целям их обработки;
							</div>
							<div className={cx('rule', 'text')}>
								<span>-</span> содержание и объем обрабатываемых ПДн должны соответствовать заявленным целям обработки. Обрабатываемые ПДн не должны быть избыточными по отношению к заявленным целям их обработки;
							</div>
							<div className={cx('rule', 'text')}>
								<span>-</span> при обработке ПДн должны быть обеспечены точность ПДн, их достаточность, а в необходимых случаях и актуальность по отношению к целям обработки ПДн. Общество должно принимать необходимые меры либо обеспечивать их принятие по удалению или уточнению неполных или неточных данных;
							</div>
							<div className={cx('rule', 'text')}>
								<span>-</span> хранение ПДн должно осуществляться в форме, позволяющей определить субъекта ПДн, не дольше, чем этого требуют цели обработки ПДн, если срок их хранения не установлен федеральным законом, договором, стороной которого, выгодоприобретателем или поручителем по которому является субъект ПДн. Обрабатываемые ПДн подлежат уничтожению либо обезличиванию по достижении целей обработки или в случае утраты необходимости в достижении этих целей, если иное не предусмотрено федеральным законом.
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className={cx('block')}>
				<div className={cx('blockTitle', 'text')}>2. Права субъектов ПДн</div>
				<div className={cx('rules')}>
					<div className={cx('rule', 'text')}>
						<span>2.1</span> В соответствии с 152-ФЗ субъект ПДн имеет право:
					</div>
					<div className={cx('rules')}>
						<div className={cx('rule', 'text')}>
							<span>2.1.1</span> На доступ к ПДн. Субъект ПДн имеет право на получение сведений, касающихся обработки его ПДн Обществом.
						</div>
						<div className={cx('rule', 'text')}>
							<span>2.1.2</span> На защиту своих прав и интересов.
							<br />
							В случае если субъект ПДн считает, что Общество нарушает его права, он вправе обжаловать действия или бездействие Общества.
							<br />
							В том числе субъект ПДн имеет право на обжалование решений, принятых на основании исключительно автоматизированной обработки ПДн.
						</div>
						<div className={cx('rule', 'text')}>
							<span>2.1.3</span> На управление своими ПДн.
							<br />
							Субъект ПДн вправе требовать от Общества уточнения его персональных данных, их блокирования или уничтожения в случае, если ПДн являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры по защите своих прав.
							<br />
							Субъект ПДн вправе требовать извещения всех лиц, которым ранее были сообщены недостоверные или неполные его ПДн, обо всех произведенных в них исключениях, исправлениях или дополнениях.
							<br />
							Субъект ПДн вправе отозвать ранее предоставленные согласия на обработку ПДн.
						</div>
					</div>
					<div className={cx('rule', 'text')}>
						<span>2.2</span> Порядок реализации прав субъектов ПДн.
					</div>
					<div className={cx('rules')}>
						<div className={cx('rule', 'text')}>
							<span>2.2.1</span> Субъект ПДн вправе обратиться в Общество с запросом, касающимся обработки его ПДн. Указанный запрос должен содержать:
							<div className={cx('rules')}>
								<div className={cx('rule', 'text')}>
									<span>-</span> номер основного документа, удостоверяющего личность субъекта ПДн или его представителя, сведения о дате выдачи указанного документа и выдавшем его органе;
								</div>
								<div className={cx('rule', 'text')}>
									<span>-</span> сведения, подтверждающие участие субъекта ПДн в отношениях с Обществом (номер договора, дата заключения договора, условное словесное обозначение и (или) иные сведения), либо сведения, иным образом подтверждающие факт обработки ПДн Обществом;
								</div>
								<div className={cx('rule', 'text')}>
									<span>-</span>подпись субъекта ПДн или его представителя.
								</div>
								<div className={cx('rule', 'text')}>
									Запрос может быть направлен в форме электронного документа и подписан электронной подписью в соответствии с законодательством Российской Федерации.
									<br />
									Общество рассматривает запрос субъекта ПДн в течение 10 рабочих дней с момента обращения либо получения оператором соответствующего запроса. Указанный срок может быть продлен, но не более чем на 5 рабочих дней в случае направления Обществом в адрес субъекта ПДн мотивированного уведомления с указанием причин продления срока предоставления запрашиваемой информации.
									<br />
									Общество вправе не рассматривать анонимные запросы, запросы без подписи и запросы, не позволяющие однозначно идентифицировать субъекта ПДн.
								</div>
							</div>
						</div>

						<div className={cx('rule', 'text')}>
							<span>2.2.2</span> Субъект вправе отозвать согласие на обработку ПДн.
							<br />
							После получения такого отзыва Общество прекратит обработку ПДн или обеспечит прекращение их обработки лицом, действующим по поручению Общества, в течение 30 календарных дней.
							<br />
							При этом Общество вправе осуществлять обработку ПДн без согласия субъекта ПДн на основаниях, предусмотренных 152-ФЗ или другими федеральными законами.
						</div>
					</div>
					<div className={cx('rule', 'text')}>
						<span>2.3</span> Контакты для направления запросов, обращений, требований, отзывов согласий на обработку ПДн и иных документов, связанных с обработкой ПДн:
					</div>
					<div className={cx('rules')}>
						<div className={cx('rule', 'text')}>
							<span>2.3.1</span> Почтовый адрес: 127030, г. Москва, ул. Сущевская, д. 27 стр. 4, эт. 2, ком. 3, пом. III.
						</div>
						<div className={cx('rule', 'text')}>
							<span>2.3.2</span> Адрес электронной почты: <a href='mailto:info@secure-t.ru'>info@secure-t.ru</a>.
						</div>
					</div>
				</div>
			</div>

			<div className={cx('block')}>
				<div className={cx('blockTitle', 'text')}>3. Права и обязанности Общества</div>
				<div className={cx('rules')}>
					<div className={cx('rule', 'text')}>
						<span>3.1</span> Обязанности Общества:
						<div className={cx('rules')}>
							<div className={cx('rule', 'text')}>
								<span>-</span> организовывать обработку ПДн в Обществе в соответствии с требованиями 152-ФЗ;
							</div>
							<div className={cx('rule', 'text')}>
								<span>-</span> обеспечивать защиту ПДн, обрабатываемых в Обществе, от их неправомерного использования или утраты;
							</div>
							<div className={cx('rule', 'text')}>
								<span>-</span> соблюдать конфиденциальность ПДн;
							</div>
							<div className={cx('rule', 'text')}>
								<span>-</span> своевременно и в соответствии с требованиями законодательства Российской Федерации реагировать на обращения и запросы субъектов ПДн, и их законных представителей;
							</div>
							<div className={cx('rule', 'text')}>
								<span>-</span> сообщать в Роскомнадзор по запросу этого органа необходимую информацию в течение 10 рабочих дней с даты получения такого запроса;
							</div>
							<div className={cx('rule', 'text')}>
								<span>-</span> устранять нарушения законодательства, допущенные при обработке ПДн;
							</div>
							<div className={cx('rule', 'text')}>
								<span>-</span> уточнять, блокировать и уничтожать ПДн в случаях, предусмотренных 152-ФЗ;
							</div>
							<div className={cx('rule', 'text')}>
								<span>-</span> выполнять иные требования, установленные 152-ФЗ.
							</div>
						</div>
					</div>
					<div className={cx('rule', 'text')}>
						<span>3.2</span> При обработке ПДн в Обществе принимают необходимые правовые, организационные и технические меры или обеспечивают их принятие для защиты ПДн от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения ПДн, а также иных неправомерных действий в отношении ПДн.
					</div>
					<div className={cx('rule', 'text')}>
						<span>3.3</span> В Обществе назначается ответственный за обработку ПДн.
					</div>
					<div className={cx('rule', 'text')}>
						<span>3.4</span> Общество вправе принимать локальные нормативные акты в развитие настоящей Политики.
					</div>
				</div>
			</div>

			<div className={cx('block')}>
				<div className={cx('blockTitle', 'text')}>4. Порядок и условия обработки ПДн</div>

				<div className={cx('rules')}>
					<div className={cx('rule', 'text')}>
						<span>4.1</span> Способы обработки ПДн.
						<br />
						Обработка персональных данных субъектов персональных данных осуществляется как на бумажных носителях, так и с использованием средств автоматизации (с помощью средств вычислительной техники) путем (включая, но не ограничиваясь):
						<div className={cx('rules')}>
							<div className={cx('rule', 'text')}>
								<span>-</span> получения оригиналов необходимых документов; копирования оригиналов документов;
							</div>
							<div className={cx('rule', 'text')}>
								<span>-</span> получением электронного вида информации, содержащей персональные данные;
							</div>
							<div className={cx('rule', 'text')}>
								<span>-</span> внесения сведений в учетные формы на бумажных и электронных носителях;
							</div>
							<div className={cx('rule', 'text')}>
								<span>-</span> формирования персональных данных в ходе кадровой работы;
							</div>
							<div className={cx('rule', 'text')}>
								<span>-</span> внесения персональных данных в информационные системы.
							</div>
						</div>
					</div>
					<div className={cx('rule', 'text')}>
						<span>4.2</span> Перечень действий (операций) с ПДн, которые могут совершаться Обществом: сбор, получение, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передача (распространение, предоставление, доступ), удаление, уничтожение персональных данных.
						<br />
						При этом обработка ПДн, разрешенных субъектами ПДн для распространения, осуществляется исключительно при условии получения отдельного согласия в порядке, установленном 152-ФЗ.
						<br />
						В предоставленных Обществу согласиях на обработку ПДн, разрешенных для распространения, субъекты ПДн не установили условия и запреты при распространении ПДн. В случае установления таких условий и запретов информация будет незамедлительно опубликована на Сайтах.
					</div>
					<div className={cx('rule', 'text')}>
						<span>4.3</span> Общество вправе поручать обработку ПДн другому лицу на основании заключаемого с этим лицом договора или передавать ПДн другим лицам. В указанных случаях Общество обязуется осуществлять такие действия с соблюдением требований, установленных 152-ФЗ.
					</div>
					<div className={cx('rule', 'text')}>
						<span>4.4</span> Правовые основания обработки ПДн.
						<br />
						Общество обрабатывает ПДн на основании:
						<div className={cx('rules')}>
							<div className={cx('rule', 'text')}>
								<span>-</span> нормативных правовых актов, регулирующие отношения, связанные с деятельностью оператора, включая, но не ограничиваясь, Трудовой кодекс Российской Федерации, Гражданский кодекс Российской Федерации, Федеральный закон от 08.02.1998 № 14-ФЗ «Об обществах с ограниченной ответственностью», Федеральный закон от 27.07.2006 г. № 149-ФЗ «Об информации, информационных технологиях и о защите информации»;
							</div>
							<div className={cx('rule', 'text')}>
								<span>-</span> уставных документов и локальных нормативных актов Общества;
							</div>
							<div className={cx('rule', 'text')}>
								<span>-</span> договоров, заключаемых между Обществом и субъектами ПДн, включая трудовые договоры;
							</div>
							<div className={cx('rule', 'text')}>
								<span>-</span> договоров, заключаемых между Обществом и его контрагентами (операторами ПДн), включая договоры поручения на обработку ПДн;
							</div>
							<div className={cx('rule', 'text')}>
								<span>-</span> согласий на обработку ПДн.
							</div>
							<div className={cx('rule', 'text')}>
								Помимо случаев, прямо предусмотренных законодательством Российской Федерации, Общество вправе обрабатывать ПДн для защиты своих прав и законных интересов.
							</div>
						</div>
					</div>
					<div className={cx('rule', 'text')}>
						<span>4.5</span> Условия прекращения обработки ПДн.
						<br />
						Общество прекращает обработку ПДн в следующих случаях:
						<div className={cx('rules')}>
							<div className={cx('rule', 'text')}>
								<span>-</span> достижение целей обработки ПДн;
							</div>
							<div className={cx('rule', 'text')}>
								<span>-</span> истечение срока действия или отзыв субъектом ПДн согласия на их обработку, если (1) сохранение персональных данных более не требуется для целей; (2) иное не предусмотрено договором, стороной которого, выгодоприобретателем или поручителем по которому является субъект ПДн; (3) Общество не вправе осуществлять обработку без согласия субъекта ПДн на основаниях, предусмотренных 152-ФЗ или иными федеральными законами; (4) иное не предусмотрено иным соглашением между оператором и субъектом ПДн;
							</div>
							<div className={cx('rule', 'text')}>
								<span>-</span> получение уведомления контрагента Общества об истечении срока действия согласия, на основании которых ПДн переданы или поручены на обработку Обществу;
							</div>
							<div className={cx('rule', 'text')}>
								<span>-</span> получения требования о прекращении обработки ПДн в порядке, предусмотренном ч. 5.1 ст. 21 152-ФЗ, если Общество не вправе осуществлять обработку без согласия субъекта ПДн на основаниях, предусмотренных 152-ФЗ;
							</div>
							<div className={cx('rule', 'text')}>
								<span>-</span> получение требования о прекращении передачи (распространения, предоставления, доступа) ПДн, разрешенных субъектом ПДн для распространения в порядке, предусмотренном ч. 12 ст. 10.1 152-ФЗ;
							</div>
							<div className={cx('rule', 'text')}>
								<span>-</span> подтверждение факта неправомерности обработки ПДн.
							</div>
						</div>
					</div>
					<div className={cx('rule', 'text')}>
						<span>4.6</span> При сборе ПДн, в том числе посредством информационно-телекоммуникационной сети «Интернет», оператор обеспечивает запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение ПДн граждан Российской Федерации с использованием баз данных, находящихся на территории Российской Федерации.
					</div>
					<div className={cx('rule', 'text')}>
						<span>4.7</span> Информация о целях обработки ПДн, а также категориях и перечне обрабатываемых ПДн, категориях субъектов, ПДн которых обрабатываются, способах и сроках их обработки и хранения, порядке уничтожения содержится в Приложении № 1 к настоящей Политике.
					</div>
					<div className={cx('rule', 'text')}>
						<span>4.8</span> Возможна обработка ПДн в иных целях, которые носят несистематический и разовый характер, если у Общества имеются основания для такой обработки, предусмотренные 152-ФЗ или иными нормативными правовыми актами.
					</div>
					<div className={cx('rule', 'text')}>
						<span>4.9</span> Порядок уничтожения ПДн при достижении целей их обработки или при наступлении иных оснований прекращения их обработки:
						<div className={cx('rules')}>
							<div className={cx('rule', 'text')}>
								<span>-</span> на бумажном носителе: уничтожаются путем измельчения в шредере;
							</div>
							<div className={cx('rule', 'text')}>
								<span>-</span> в электронном виде: (1) уничтожение ПДн средствами встроенных функций ИСПДн, либо (2) ПДн стираются с информационных носителей или физически уничтожаются сами информационные носители, на которых хранятся ПДн.
							</div>
						</div>
					</div>
					<div className={cx('rule', 'text')}>
						<span>4.10</span> Уничтожение персональных данных производится с соблюдением Требований к подтверждению уничтожения ПДн, которые утверждены приказом Роскомнадзора от 28.10.2022 № 179. Подтверждением уничтожения ПДн является акт об уничтожении ПДн, подписываемый лицом (лицами), уничтожившим (уничтожившими) ПДн субъекта.
					</div>
					<div className={cx('rule', 'text')}>
						<span>4.11</span> При сборе ПДн, в том числе посредством информационно-телекоммуникационной сети «Интернет», оператор обеспечивает запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение ПДн граждан Российской Федерации с использованием баз данных, находящихся на территории Российской Федерации.
					</div>
					<div className={cx('rule', 'text')}>
						<span>4.12</span> Трансграничная передача ПДн не осуществляется.
					</div>
				</div>
			</div>

			<div className={cx('block')}>
				<div className={cx('blockTitle', 'text')}>5. Обеспечение безопасности ПДн</div>

				<div className={cx('rules')}>
					<div className={cx('rule', 'text')}>
						<span>5.1</span> Общество стремится обеспечить безопасность обрабатываемых ПДн, для чего принимает необходимые правовые, организационные и технические меры.
					</div>
					<div className={cx('rule', 'text')}>
						<span>5.2</span> В Обществе реализуются следующие требования законодательства в области ПДн:
						<div className={cx('rules')}>
							<div className={cx('rule', 'text')}>
								<span>-</span> назначено лицо, ответственное за организацию обработки ПДн;
							</div>
							<div className={cx('rule', 'text')}>
								<span>-</span> приняты локальные нормативные акты, определяющие политику Общества в отношении обработки ПДн, включая настоящую Политику;
							</div>
							<div className={cx('rule', 'text')}>
								<span>-</span> осуществляется внутренний контроль (аудит) соответствия обработки ПДн требованиям 152-ФЗ;
							</div>
							<div className={cx('rule', 'text')}>
								<span>-</span> осуществлена оценка вреда, который может быть причинен субъектам ПДн в случае нарушения 152-ФЗ;
							</div>
							<div className={cx('rule', 'text')}>
								<span>-</span> работники Общества, непосредственно осуществляющие обработку ПДн, ознакомлены с положениями законодательства РФ в области ПДн, в том числе требованиями к защите ПДн, а также локальными нормативными актами Общества в отношении обработки ПДн;
							</div>
							<div className={cx('rule', 'text')}>
								<span>-</span> лица, осуществляющие обработку ПДн без использования средств автоматизации, проинформированы о факте обработки ими ПДн, категориях обрабатываемых ПДн, а также об особенностях и правилах осуществления такой обработки, установленных нормативными правовыми актами федеральных органов исполнительной власти, органов исполнительной власти субъектов Российской Федерации, а также локальными правовыми актами Общества;
							</div>
							<div className={cx('rule', 'text')}>
								<span>-</span> работниками Общества, непосредственно осуществляющими обработку ПДн, даны обязательства о неразглашении ПДн, к которым им предоставлен доступ на период исполнения должностных обязанностей;
							</div>
							<div className={cx('rule', 'text')}>
								<span>-</span> Обществом на Сайтах опубликована настоящая Политика;
							</div>
							<div className={cx('rule', 'text')}>
								<span>-</span> по требованию Роскомнадзора Общество готово предоставить локальные нормативные акты, определяющие политику Общества в отношении обработки ПДн, включая настоящую Политику.
							</div>
						</div>
					</div>
					<div className={cx('rule', 'text')}>
						<span>5.3</span> Обществом приняты следующие меры по обеспечению безопасности ПДн при их обработке:
						<div className={cx('rules')}>
							<div className={cx('rule', 'text')}>
								<span>-</span> определены требования к защите ПДн от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения ПДн, а также от иных неправомерных действий в отношении ПДн;
							</div>
							<div className={cx('rule', 'text')}>
								<span>-</span> определены угрозы безопасности ПДн при их обработке в ИСПДн;
							</div>
							<div className={cx('rule', 'text')}>
								<span>-</span> применяются организационные и технические меры по обеспечению безопасности ПДн в ИСПДн, необходимые для выполнения требований к защите ПДн в  соответствии с постановлением Правительства Российской Федерации от 01.11.2012 № 1119 «Об утверждении требований к защите персональных данных при их обработке в информационных системах персональных данных», а именно:
								<div className={cx('rules')}>
									<div className={cx('rule', 'text')}>
										<span>-</span> организован режим безопасности помещений, в которых размещены ИСПДн, препятствующий возможности неконтролируемого проникновения или пребывания в этих помещениях лиц, не имеющих права доступа в эти помещения;
									</div>
									<div className={cx('rule', 'text')}>
										<span>-</span> обеспечивается сохранность носителей ПДн и ведется их учет;
									</div>
									<div className={cx('rule', 'text')}>
										<span>-</span> утвержден перечень лиц, которым доступ к ПДн, обрабатываемым в ИСПДн, необходим для выполнения должностных обязанностей;
									</div>
									<div className={cx('rule', 'text')}>
										<span>-</span> используются средства защиты информации, прошедшие процедуру оценки соответствия требованиям законодательства Российской Федерации в области обеспечения безопасности информации;
									</div>
								</div>
							</div>
							<div className={cx('rule', 'text')}>
								<span>-</span> проводится оценка эффективности принимаемых мер по обеспечению безопасности ПДн до ввода ИСПДн в эксплуатацию;
							</div>
							<div className={cx('rule', 'text')}>
								<span>-</span> определен порядок обнаружения фактов несанкционированного доступа к ПДн и принятия мер, в том числе по обнаружению, предупреждению и ликвидации последствий компьютерных атак на ИСПДн и по реагированию на компьютерные инциденты в них;
							</div>
							<div className={cx('rule', 'text')}>
								<span>-</span> в отношении каждой категории ПДн определены места хранения ПДн (материальных носителей) и перечень лиц, осуществляющих обработку ПДн либо имеющих к ним доступ;
							</div>
							<div className={cx('rule', 'text')}>
								<span>-</span> определен порядок восстановления ПДн, модифицированных или уничтоженных вследствие несанкционированного доступа к ним;
							</div>
							<div className={cx('rule', 'text')}>
								<span>-</span> установлены права доступа к ПДн, обрабатываемым в ИСПДн, а также обеспечиваются регистрация и учет всех действия, совершаемых с ПДн в ИСПДн;
							</div>
							<div className={cx('rule', 'text')}>
								<span>-</span> осуществляется постоянный контроль за принимаемыми мерами по обеспечению безопасности ПДн и уровня защищенности ИСПДн;
							</div>
							<div className={cx('rule', 'text')}>
								<span>-</span> при фиксации ПДн на материальных носителях не допускается фиксация на одном материальном носителе ПДн, цели обработки которых заведомо не совместимы. Для обработки различных категорий ПДн, осуществляемой без использования средств автоматизации, для каждой категории ПДн используется отдельный материальный носитель.
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className={cx('block')}>
				<div className={cx('blockTitle', 'text')}>6. Заключительные положения</div>

				<div className={cx('rules')}>
					<div className={cx('rule', 'text')}>
						<span>6.1</span> Политика утверждается приказом генерального директора Общества.
					</div>
					<div className={cx('rule', 'text')}>
						<span>6.2</span> После ее утверждения Политика подлежит опубликованию на Сайтах Общества.
					</div>
					<div className={cx('rule', 'text')}>
						<span>6.3</span> Общество вправе в любой момент изменить редакцию Политики с последующим опубликованием новой редакции на сайтах Общества.
					</div>
				</div>
			</div>
		</div>
	</Container >
)
