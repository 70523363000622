import { ModalContext } from './../contexts/ModalContext'
import { useState, useEffect, useContext } from 'react'
import { TgAPI } from '../api/Tg'
export function useContact() {
	const [name, setName] = useState<string>('')
	const [phone, setPhone] = useState<string>('')
	const [email, setEmail] = useState<string>('')
	const [loading, setLoading] = useState<boolean>(false)
	const [status, setStatus] = useState<'error' | 'ok' | null>(null)

	const [errors, setErrors] = useState<Array<'name' | 'phone' | 'email'>>([])

	const { openThanks, closeModal } = useContext(ModalContext)

	useEffect(() => {
		setErrors([])
		setStatus(null)
	}, [name, phone, email])

	const submit = async (type: string) => {
		const errs: Array<'name' | 'phone' | 'email'> = []
		if (!name) errs.push('name')
		if (!phone || !/^(\+7 \(([0-9]){3}\) ([0-9]){3}-([0-9]){2}-([0-9]){2})$/gm.test(phone))
			errs.push('phone')
		// eslint-disable-next-line
		if (!email || !/[^@]+@[^\.]+\..+/m.test(email)) errs.push('email')

		if (errs.length !== 0) {
			setErrors(errs)
			return
		}

		try {
			setLoading(true)
			await TgAPI.Feedback(name, phone, email)
			setStatus('ok')
			closeModal()
			openThanks()
		} catch (err) {
			console.error(err)
			setStatus('error')
		} finally {
			setLoading(false)
		}
		setTimeout(() => {
			setStatus(null)
		}, 3000)
	}
	//putin_molodec@kremlin.ru
	return {
		email,
		name,
		phone,
		setEmail,
		setPhone,
		setName,
		submit,
		loading,
		status,
		errors,
	}
}
