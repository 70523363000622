import { FC, useState } from 'react'
import classNamesBind from 'classnames/bind'

import { ProblemsProps } from './Problems.d'

import styles from './Problems.module.scss'
import { Container } from '../Container'
import { problems } from '../../../assets/mocs/problems'

const cx = classNamesBind.bind(styles)

const Problems: FC<ProblemsProps.Props> = () => {
	const [hoverIndex, setHoverIndex] = useState(0)

	const handleMouseOver = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
		if (hoverIndex !== index) setHoverIndex(index)
	}

	const handleMouseOut = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
		if (hoverIndex === index) setHoverIndex(0)
	}

	return (
		<Container className={cx('wrapper', 'container')}>
			<div className={cx('title')}>Проблемы, которые мы решаем</div>
			<Container className={cx('subtitle')}>
				Мы создаём платформу для повышения осведомленности сотрудников в области информационной
				безопасности
			</Container>
			{problems.length &&
				problems.map((item, i) => {
					return (
						<div
							key={`problem-${item.id}`}
							className={cx('item', `${hoverIndex === i ? 'hover' : ''}`)}
							onMouseOver={(event) => handleMouseOver(event, i)}
							onMouseOut={(event) => handleMouseOut(event, i)}
						>
							<div className={cx('item-title')}>{item.title}</div>
							<img
								alt=''
								src={item.image}
							/>
							<div className={cx('item-text')}>{item.text}</div>
						</div>
					)
				})}
		</Container>
	)
}

export default Problems
