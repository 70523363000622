import { FC } from 'react'
import classNamesBind from 'classnames/bind'

import { MainPageProps } from './MainPage.d'

import styles from './MainPage.module.scss'
import {
	Advice,
	Container,
	FirstScreen,
	Hows,
	Laws,
	License,
	Problems,
	Train,
} from '../../components/common'
import { Link } from 'react-router-dom'

const cx = classNamesBind.bind(styles)

const MainPage: FC<MainPageProps.Props> = () => {
	return (
		<div className={cx('wrapper')}>
			<FirstScreen />
			<Problems />
			<Hows />
			<Laws />
			<License />
			<Train />
			<Advice />
			<Container className={cx('more')}>
				Если хотите узнать побольше о нашей компании, нажмите <Link to='/about'>сюда</Link>
			</Container>
		</div>
	)
}

export default MainPage
