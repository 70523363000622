import { FC, useEffect, useState } from 'react'
import classNamesBind from 'classnames/bind'

import { NewsItemPageProps } from './NewsItemPage.d'

import styles from './NewsItemPage.module.scss'
import { useNavigate, useParams } from 'react-router-dom'
import { news } from '../../assets/mocs'
import { INewsItem } from '../../types/INews'
import { Container, News } from '../../components/common'

import parse from 'html-react-parser'

const cx = classNamesBind.bind(styles)

const NewsItemPage: FC<NewsItemPageProps.Props> = () => {
	const navigate = useNavigate()
	const { id } = useParams()

	const [newsInfo, setNewsInfo] = useState<INewsItem | null>(null)
	const [error, setError] = useState(false)

	useEffect(() => {
		const newsItem = news.filter((item) => item.id == id)
		if (newsItem.length > 0) {
			setNewsInfo(newsItem[0])
		} else {
			setError(true)
		}
	}, [id])

	useEffect(() => {
		if (error) {
			navigate('/404')
		}
	}, [error])

	if (!newsInfo) return <></>

	const handleClick = () => {
		navigate(-1)
	}

	const restNews = news.filter((n) => n.id != id)

	return (
		<div className={cx('wrapper')}>
			<Container className={cx('container')}>
				<div className={cx('imageWrapper')}>
					<img
						src={newsInfo.image}
						alt={`Новость ${newsInfo.id}`}
					/>
					{/* <h1 className={cx('title')}>{newsInfo.title}</h1> */}
				</div>

				<div className={cx('second-title')}>
					<span onClick={() => handleClick()}>
						<svg
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M15 19L8 12L15 5'
								stroke='#BAB9BB'
								strokeWidth='2'
								strokeLinecap='round'
								strokeLinejoin='round'
							/>
						</svg>
						Назад
					</span>
					<Container>{newsInfo.title}</Container>
				</div>
				<Container className={cx('text')}>{parse(newsInfo.text)}</Container>
				{restNews.length > 0 ? (
					<News
						title='Другие новости'
						items={restNews}
					/>
				) : (
					<></>
				)}
			</Container>
		</div>
	)
}

export default NewsItemPage
