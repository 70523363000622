import { FC } from 'react'
import classNamesBind from 'classnames/bind'
import * as SlickSlider from 'react-slick'

import { SliderProps } from './Slider.d'

import styles from './Slider.module.scss'
import { Container } from '../../Container'

const cx = classNamesBind.bind(styles)

const Slider: FC<SliderProps.Props> = (props) => {
	const { className, title, items, settings,sliderItemClassname, isFullWidth = false } = props

	if (!items.length) {
		return <></>
	}

	return (
		<Container className={cx('wrapper', className, `${isFullWidth ? 'full' : ''}`)}>
			{title && (
				<Container className={cx('title-wrapper')}>
					<div className={cx('title')}>{title}</div>
				</Container>
			)}

			<div className={cx('inner')}>
				<SlickSlider.default {...settings}>
					{items.map((item) => {
						return (
							<div
								key={`rewiew-${item.id}`}
								className={cx('slider-item', sliderItemClassname)}
							>
								<img
									alt=''
									src={item.image}
								/>
								{item.hover ? (
									<img
										alt=''
										src={item.hover}
										className={cx('hover')}
									/>
								) : null}
							</div>
						)
					})}
				</SlickSlider.default>
			</div>
		</Container>
	)
}

export default Slider
