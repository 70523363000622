import { ID, UUID } from '../../types/ID'

interface IMenuItem {
	id?: UUID | ID
	name: string
	link: string
	otherPage?: boolean
}

export const footerMenu: IMenuItem[] = [
	{
		name: 'Магазин курсов',
		link: 'https://educate-t.ru/',
		otherPage: true,
	},
	{
		name: 'Политика в отношении обработки персональных данных',
		link: '/privacy',
	},
	{
		name: 'Лицензии',
		link: '/licenses.pdf',
	},
]
