import classNamesBind from 'classnames/bind'
import { Container } from '../../components/common'

import styles from './TextPage.module.scss'

const cx = classNamesBind.bind(styles)

export const agreementText = (
	<Container className={cx('container')}>
		<div className={cx('title')}>Согласие на обработку персональных данных</div>
		<div className={cx('blocks')}>
			<div className={cx('block')}>
				<div className={cx('blockText', 'text')}>
					Оставляя заявку на сайте через форму обратной связи, я даю свое согласие Обществу с ограниченной ответственностью «Секьюр-Ти» (место нахождения 127030, г. Москва, ул. Сущевская, д. 27 стр. 4, эт. 2, ком. 3, пом. III, ОГРН 1197746646536, ИНН 9704004921, далее – «Общество») на обработку моих персональных данных, а именно:
				</div>
				<div className={cx('rules')}>
					<div className={cx('rule', 'text')}>
						<span>-</span> фамилия, имя, отчество (при наличии);
					</div>
					<div className={cx('rule', 'text')}>
						<span>-</span> адрес электронной почты;
					</div>
					<div className={cx('rule', 'text')}>
						<span>-</span> контактный номер телефона;
					</div>
					<div className={cx('rule', 'text')}>
						<span>-</span> сведения о месте работы (наименование организации) и должности с целью возможного сотрудничества Общества с организацией, которую я представляю, в том числе обсуждения возможности такого сотрудничества.
					</div>
				</div>
				<div className={cx('blockText', 'text')}>
					Я соглашаюсь с тем, что мои персональные данные будут обрабатываться следующими способами: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, блокирование, удаление, уничтожение персональных данных, а также передача (предоставление, доступ).
				</div>
				<div className={cx('blockText', 'text')}>
					Я соглашаюсь с тем, что мои персональные данные для достижения цели их обработки могут быть переданы следующим лицам:
				</div>
				<div className={cx('rules')}>
					<div className={cx('rule', 'text')}>
						<span>-</span> Обществу с ограниченной ответственностью «СОЛАР СЕКЬЮРИТИ» (место нахождения 127015, г. Москва, ул. Вятская, д. 35 стр. 4, эт. 4, пом. 7, ком. 12, 20-23, 25-34, 48-54, 65, ОГРН 1157746204230, ИНН 7718099790);
					</div>
					<div className={cx('rule', 'text')}>
						<span>-</span> Обществу с ограниченной ответственностью «РТК Информационная безопасность» (место нахождения 125009, г. Москва, переулок Никитский, д. 7, стр. 1, эт. 2, пом. I, ком. 58, ОГРН 1167746458065, ИНН 7704356648).
					</div>
				</div>
				<div className={cx('blockText', 'text')}>
					Обработка моих персональных данных может быть автоматизированной, а также без использования средств автоматизации обработки.

				</div>
				<div className={cx('blockText', 'text')}>
					Срок действия согласия – до отказа от сотрудничества, в том числе обсуждения возможности такого сотрудничества.

				</div>
				<div className={cx('blockText', 'text')}>
					Подтверждаю, что я являюсь дееспособным лицом и могу самостоятельно давать согласие на обработку своих персональных данных.
				</div>
				<div className={cx('blockText', 'text')}>
					Согласие дано добровольно, свободно, и в своем интересе, а также является конкретным, информированным, сознательным, понятным и исчерпывающе предметным.

				</div>
				<div className={cx('blockText', 'text')}>
					Я уведомлен(а), что согласие на обработку моих персональных данных может быть отозвано путем направления (подачи) в свободной форме (1) письменного заявления по месту нахождения Общества, либо (2) по адресу электронной почты <a href='mailto:info@secure-t.ru'>info@secure-t.ru</a> с адреса электронной почты, указанного мной в заявке.
				</div>
			</div>
		</div>
	</Container>
)
