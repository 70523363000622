import { FC, useState } from 'react'
import classNamesBind from 'classnames/bind'

import { RewiewsProps, RewiewsItemProps } from './Rewiews.d'

import styles from './Rewiews.module.scss'
import { Container } from '../../Container'
import { rewiews } from '../../../../assets/mocs/rewiews'

const cx = classNamesBind.bind(styles)

export const RewiewsItem: FC<RewiewsItemProps.Props> = (props) => {
	const { item } = props

	const [isHover, setIsHover] = useState(false)

	const handleMouseEnter = () => {
		setIsHover(true)
	}
	const handleMouseLeave = () => {
		setIsHover(false)
	}

	return (
		<div
			className={cx('item')}
			style={{
				background: item.color,
				boxShadow: isHover ? (item.shadow ? item.shadow : '0 6px 16px rgba(81, 54, 85, 0.16)') : '',
			}}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<div className={cx('item-name')}>{item.name}</div>
			<div className={cx('item-role')}>{item.role}</div>
			<div className={cx('item-text')}>{item.text}</div>
			<div className={cx('item-icon')}>
				<img
					alt=''
					src={item.image}
				/>
			</div>
		</div>
	)
}

const Rewiews: FC<RewiewsProps.Props> = () => {
	return (
		<Container className={cx('wrapper')}>
			<div className={cx('title')}>Отзывы</div>
			<div className={cx('inner')}>
				{rewiews.length &&
					rewiews.map((item) => {
						return (
							<RewiewsItem
								key={`rewiew-${item.id}`}
								item={item}
							/>
						)
					})}
			</div>
		</Container>
	)
}

export default Rewiews
