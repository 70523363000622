import { IVacancy } from '../../types/IVacancy'
import ninja from '../images/ninja.png'

export const vacancy: IVacancy[] = [
	{
		id: 0,
		title: 'Менеджер по продажам',
		stacks: ['Ответственность', 'soft skills'],
		image: ninja,
		text: `Компания «Секьюр-Ти» разрабатывает собственный продукт - платформу по повышению осведомленности в сфере ИБ. Для того, чтобы и дальше развивать самую прекрасную платформу на диком западе (Upd: теперь без запада, он больше не в почете) мы находимся в поисках крайне перспективного и активного менеджера по продажам.`,
		lists: [
			{
				title: 'Что надо будет делать:',
				texts: [
					'Совершать исходящие звонки по холодной клиентской базе;',
					'Прием входящих заявок и обработка лидов;',
					'Назначать встречи с компанией;',
					'Вести клиента на протяжении всего сотрудничества, работать с базой, продлевать сотрудничество с текущими рекламодателями;',
					'Прорабатывать разные каналы продаж.',
				],
			},
			{
				title: 'Наши ожидания:',
				texts: [
					'Грамотная речь и умение находить общий язык с людьми;',
					'Желание и умение учиться новому в сфере продаж;',
					'Целеустремлённость и настойчивость.',
				],
			},
			{
				title: 'Мы предлагаем:',
				texts: [
					'Крутой уютный офис с масажером для ног, большой стол, классный стул;',
					'Гибридный формат работы: 3 дня офис, 2 дня удаленно;',
					'Оформление по ТК РФ в аккредитованной IT компании;',
					'Ноутбук мы можем предоставить, но если вы предпочитаете работать на своем, то мы можем предоставить вам корпоративный стикер;',
					'Наш корпоративный стиль - отсутствие корпоративного стиля. В офисе можно ходить как в тапочках, так и в спортивной одежде;',
					'Гоняем на картинг, устраиваем nerf-войну, заказываем редбулл ящиками, в общем - своя атмосфера.',
				],
			},
			{
				title: 'Бонусы:',
				texts: ['Ящик редбулла'],
			},
		],
	},
]
