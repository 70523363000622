import { IPartner } from './../../types/IPartners'

// Нижний слайдер серые
import partnersDown1 from '../images/partners/down/item1.png'
import partnersDown2 from '../images/partners/down/item2.png'
import partnersDown3 from '../images/partners/down/item3.png'
// import partnersDown4 from '../images/partners/down/item4.png'
import partnersDown7 from '../images/partners/down/item7.png'
import a1 from '../images/partners/down/A1_red_logo.png'
import itprotect from '../images/partners/down/itprotect.png'
import ts_solution from '../images/partners/down/Ts solution.png'
import informguard from '../images/partners/down/informguard.png'

// Нижний слайдер цветные
import partnersDown1Hover from '../images/partners/down/hover/item1.png'
import partnersDown2Hover from '../images/partners/down/hover/item2.png'
import partnersDown3Hover from '../images/partners/down/hover/item3.png'
// import partnersDown4Hover from '../images/partners/down/hover/item4.png'
import partnersDown7Hover from '../images/partners/down/hover/item7.png'
import a1Hover from '../images/partners/down/hover/A1_red_logo.png'
import itprotectHover from '../images/partners/down/hover/itprotect.png'
import ts_solutionHover from '../images/partners/down/hover/Ts solution.png'
import informguardHover from '../images/partners/down/hover/informguard.png'

// Верхний слайдер серые
import partnersUp1 from '../images/partners/up/item1.png'
import partnersUp3 from '../images/partners/up/item3.png'
import partnersUp5 from '../images/partners/up/item5.png'
import partnersUp6 from '../images/partners/up/item6.png'
import partnersUp10 from '../images/partners/up/item10.png'
import partnersUp12 from '../images/partners/up/item12.png'
import partnersUp13 from '../images/partners/up/item13.png'
import partnersUp14 from '../images/partners/up/item14.png'
import partnersUp16 from '../images/partners/up/item16.png'
import partnersUp17 from '../images/partners/up/item17.png'

import cemros from '../images/partners/up/cemros.jpg'
import dks from '../images/partners/up/DKC.png'
import ecovina from '../images/partners/up/econiva.png'
import faberlic from '../images/partners/up/Faberlic.png'
import remind from '../images/partners/up/Remind.png'
import rjd from '../images/partners/up/rjd.png'
import zenden from '../images/partners/up/Zenden.png'

// Верхний слайдер цветные
import partnersUp1Hover from '../images/partners/up/hover/item1.png'
import partnersUp3Hover from '../images/partners/up/hover/item3.png'
import partnersUp5Hover from '../images/partners/up/hover/item5.png'
import partnersUp6Hover from '../images/partners/up/hover/item6.png'
import partnersUp10Hover from '../images/partners/up/hover/item10.png'
import partnersUp12Hover from '../images/partners/up/hover/item12.png'
import partnersUp13Hover from '../images/partners/up/hover/item13.png'
import partnersUp14Hover from '../images/partners/up/hover/item14.png'
import partnersUp16Hover from '../images/partners/up/hover/item16.png'
import partnersUp17Hover from '../images/partners/up/hover/item17.png'

import cemrosHover from '../images/partners/up/hover/cemros.jpg'
import dksHover from '../images/partners/up/hover/DKC.png'
import ecovinaHover from '../images/partners/up/hover/econiva.png'
import faberlicHover from '../images/partners/up/hover/Faberlic.png'
import remindHover from '../images/partners/up/hover/Remind.png'
import rjdHover from '../images/partners/up/hover/rjd.png'
import zendenHover from '../images/partners/up/hover/Zenden.png'

export const partnersUp: IPartner[] = [
	{ id: 1, image: partnersUp1, hover: partnersUp1Hover },
	{ id: 3, image: partnersUp3, hover: partnersUp3Hover },
	{ id: 5, image: partnersUp5, hover: partnersUp5Hover },
	{ id: 6, image: partnersUp6, hover: partnersUp6Hover },
	{ id: 10, image: partnersUp10, hover: partnersUp10Hover },
	{ id: 12, image: partnersUp12, hover: partnersUp12Hover },
	{ id: 13, image: partnersUp13, hover: partnersUp13Hover },
	{ id: 14, image: partnersUp14, hover: partnersUp14Hover },
	{ id: 16, image: partnersUp16, hover: partnersUp16Hover },
	{ id: 17, image: partnersUp17, hover: partnersUp17Hover },
	{ id: 18, image: cemros, hover: cemrosHover },
	{ id: 19, image: dks, hover: dksHover },
	{ id: 20, image: ecovina, hover: ecovinaHover },
	{ id: 21, image: faberlic, hover: faberlicHover },
	{ id: 22, image: remind, hover: remindHover },
	{ id: 23, image: rjd, hover: rjdHover },
	{ id: 24, image: zenden, hover: zendenHover },
]
export const partnersDown: IPartner[] = [
	{ id: 0, image: partnersDown1, hover: partnersDown1Hover },
	{ id: 7, image: a1, hover: a1Hover },
	{ id: 1, image: partnersDown2, hover: partnersDown2Hover },
	{ id: 9, image: ts_solution, hover: ts_solutionHover },
	{ id: 2, image: partnersDown3, hover: partnersDown3Hover },
	{ id: 8, image: itprotect, hover: itprotectHover },
	{ id: 6, image: partnersDown7, hover: partnersDown7Hover },
	{ id: 10, image: informguard, hover: informguardHover },
]
