import { FC } from 'react'
import classNamesBind from 'classnames/bind'

import { VacanciesProps } from './Vacancies.d'

import styles from './Vacancies.module.scss'
import { vacancy } from '../../../assets/mocs'
import { NotVacancy } from './NotVacancy'
import { Vacancy } from './Vacancy'
import { Link } from 'react-router-dom'

const cx = classNamesBind.bind(styles)

const Vacancies: FC<VacanciesProps.Props> = () => {
	return (
		<div className={cx('wrapper')}>
			<div className={cx('title')}>
				{vacancy.length ? 'Стань частью нашей команды' : 'Сейчас у нас нет вакансий'}
			</div>
			{vacancy.length ? (
				vacancy.map((item, i) => (
					<Link
						to={`/vacancy/${item.id}`}
						key={`vacancy-${item.id}`}
						className={cx('link')}
					>
						<Vacancy info={item.id ? item : { ...item, id: i }} />
					</Link>
				))
			) : (
				<NotVacancy />
			)}
		</div>
	)
}

export default Vacancies
